.quote-element {
    margin: 0 0 rem(24px) 0 ;
    text-align: center;
    
    .quote {
        quotes: "«" "»" ;
    }

    .quote > p {
        display:inline;
        padding: 0;
    }    
    .quote:before {
        font-size:rem(21px);
        font-family: "Akkurat-Light";
        line-height: rem(34px);
        content: open-quote;
    }

    .quote:after {
        font-size:rem(21px);
        font-family: "Akkurat-Light";
        line-height: rem(34px);
        content: close-quote;
    }
    .author {
            margin-top: rem(10px);
            > p {
                font-style: italic;
                text-align: center;
                padding:0;
            }
    }
}
//TODO
.content-element .quote-element  + .content-element .quote-element {
    margin-top: rem(75px); border: 1px solid black; 
}