.-offer {
    .formats-info-box {
        margin: 0 0 rem(35px) 0;
        position: relative;
        
        >.pic {
            background-color: #F2F2F2;
            height: 340px;
            background-repeat: no-repeat;
            background-position: center center;
            .title-overlay {
                position: absolute;
                text-align: center;
                width: 100%;
                object {
                    margin-top:70px;
                }
                .infobox-image {
                    display:inline-block;
                    margin-top:70px;
                }
                h4 {
                    font-size: rem( 25px);
                    line-height: rem( 33px);
                    color: #003160;
                    margin-top:50px;
                }
            }
            >.formats-description {
                padding: 20px;
                color: white;
                h4 {
                    margin-bottom:10px;
                }
                p {
                    font-size: rem( 17px);
                    line-height: rem( 22px);
                }
            }
        }
        
        .pic-overlay {
            position: relative;
            overflow: hidden;
            img {
                display: block;
            }
            >.overlay {
                background-color: #011A33;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                transform: translateY( 100%);
                transition: transform 0.3s;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
            &:hover {
                >.overlay {
                    transform: translateY( 0);
                }
                >.title-overlay {
                    transform: translateY( 0);
                }
            }
        }
    }
}

@media screen and (max-width: @medium-break-point) {
    .-offer {
        .formats-info-box {
            margin-bottom: rem(45px);
        }
    }
}

@media screen and (max-width: @small-break-point) {
    .-offer {
        .teaser {
            font-size: rem(30px);
            line-height: rem(40px);
            letter-spacing: rem(0.33);
            margin-top: rem(35px);
            margin-bottom: rem(20px);
        }
    }
}