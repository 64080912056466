/*
---
name: .list-team
category: Lists/Team
tag:
 - components
compile: true
---

```jade
div.list-team
	img.pic.responsive(src="http://placehold.it/350x150")
	h4.name Mein Name
	div.function Meine Funktion bei Lernetz
```
*/
.list-team {
	margin: 0 0 rem(35px) 0;
	position:relative;
	
	 .member-picture {
		z-index: 2;
	 }

	 .member-video {
	 	z-index: 1;
	 }
	
	> a {
		> .name {
			font-size: rem( 25px );
			margin:rem( 5px ) 0;
		}
		> .function {
			font-size: rem( 15px );
			line-height: rem( 25px );
			font-family:"Akkurat-Light";
			letter-spacing: rem(0.16px);
		}

		> img {
			display: block;
		}
	}
}

 @media only screen and (max-width: @medium-break-point) {
    /* For mobile phones: */
   .list-team {
	
		> a {
			> .name {
				font-size: rem( 20px );
				margin:rem( 5px ) 0;
			}
			> .function {
				font-size: rem( 14px );
			}
		}
	}
}