@keyframes arrow-up-down {
    0%   {
		 bottom: rem(60px);
		}
	50%   {
		 bottom: rem(30px);
		}
    100% {
		 bottom: rem(60px);
		}
}

@keyframes arrow-up-down-small {
    0%   {
		 bottom: rem(30px);
		}
	50%   {
		 bottom: rem(10px);
		}
    100% {
		 bottom: rem(30px);
		}
}