@charset "UTF-8";

@font-face {
  font-family: "ln-website";
  src:url("fonts/ln-website.eot");
  src:url("fonts/ln-website.eot?#iefix") format("embedded-opentype"),
    url("fonts/ln-website.woff") format("woff"),
    url("fonts/ln-website.ttf") format("truetype"),
    url("fonts/ln-website.svg#ln-website") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "ln-website" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "ln-website" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-back-1:before {
  content: "\6e";
}
.icon-arrow:before {
  content: "\6f";
}
.icon-arrow-down:before {
  content: "\70";
}
.icon-arrow-long:before {
  content: "\71";
}
.icon-arrow-long-down:before {
  content: "\72";
}
.icon-arrow-up:before {
  content: "\74";
}
.icon-close-1:before {
  content: "\75";
}
.icon-facebook:before {
  content: "\78";
}
.icon-logo-ln:before {
  content: "\79";
}
.icon-menu-1:before {
  content: "\7a";
}
.icon-twitter:before {
  content: "\41";
}
.icon-coffee-button:before {
  content: "\61";
}
.icon-phone:before {
  content: "\62";
}
.icon-location:before {
  content: "\63";
}
.icon-mail:before {
  content: "\64";
}
