.-newsletter {

    .item-navigation a {
        color: @ln-blue;
    }

    .teaser {
        font-size: rem(45px);
        font-family: "Akkurat-Regular";
        line-height: rem(58px);
        letter-spacing: rem(0.4);
        margin: rem(98px) 0 rem(35px) 0;
        font-weight: normal;
    }

    h3 {
        margin-bottom: rem(20px);
    }
    .titel-element {
        width: inherit;
        float:left;
        h1 {
            display:inline-block;
            line-height:inherit;
            margin: 0;
            padding: 0;
        }
    }
    .paragraph-element {
        display:inline-block;
        p {
            font-size:rem(41px);
            line-height:rem(54px);
            display:inline-block;
        }
    }
    .newsletter-form {
        margin-top: 30px;
        h3 {
            display:none;
        }
        input {
            color:black;
            border:none;
        }
        input[type=submit] {
            border: 2px solid white;
            font-size:rem( 14px );
            text-transform: uppercase;
            margin-top:10px;
            color:white;
        }
    }

}

@media screen  and (max-width: @medium-break-point) {
    .-project {
        .content-element {
            p, h1, h2, h3, h4, ul, ol, .quote-element {
                padding-left: 0;
            }
        }

        .teaser {
            font-size: rem(41px);
            line-height: rem(54px);
            letter-spacing: rem(0.45);
            margin-top: rem(70px);
        }
    }
}

@media screen  and (max-width: @small-break-point) {
    .-project {
        .teaser {
            font-size: rem(30px);
            line-height: rem(40px);
            letter-spacing: rem(0.33);
            margin-top: rem(35px);
            margin-bottom: rem(20px);
        }
    }
}