/*
---
name: .page-main
category: Page Layouts/Main
tag:
 - layouts
compile: true
---

# Closed state
```jade
div.page-main
	div.content Some content
	div.navigation Some navi content
```

# Open state

*/

.page-main {
    position: relative;
    &.-open {
        >.navigation {
            transform: translateX( 0);
        }
        >.darkener {
            transform: translateX(0);
            background-color: rgba( 0, 0, 0, 0.3);
        }
    }
    &.-start {
        .page {
            visibility: hidden;
        }
    }
    >.navigation {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 500px;
        max-width: 100%;
        z-index: 15;
        transform: translateX( 100%);
        transition: transform 0.3s;
        overflow: auto;
    }
    >.header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10;
    }
    >.darkener {
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: rgba( 0, 0, 0, 0.0);
        z-index: 10;
        transform: translateX( -100%);
        transition: background-color 1s;
    }

    >.coffee {
        position: fixed;
        bottom: rem( 20px);
        //right: calc(50% - @medium-break-point-halfpercent);
        left: calc(~'50% + @{large-break-point-half} - 45px');
        z-index: 3;
        text-align: right;
    }
    >.content {
        padding-top: rem(125px);
        padding-bottom: rem(175px);
        min-height: 100vh;
        >.bottom-175 {
            margin-bottom: rem(175px);
        }
    } // style page dependend colors
    &.-start {
        .page-teaser,
        .page-header.-colored {
            .home-colors-mixin;
            a {
                &.-contact:hover {
                    .colors( @ln-red;
                    @ln-grey-light;
                    @ln-grey-light);
                }
                &.-team:hover {
                    .colors( @ln-grey-light;
                    @ln-red;
                    @ln-red);
                }
                &.-blog:hover {
                    .colors( @ln-grey;
                    @ln-white;
                    @ln-white);
                }
                &.-project:hover {
                    .colors( #d9d9da;
                    @ln-blue;
                    @ln-blue);
                }
                &.-offer:hover {
                    .colors( #fff;
                    @ln-blue;
                    @ln-blue);
                }
                &.-events:hover {
                    .colors( #fff;
                    @ln-blue;
                    @ln-blue);
                }
            }
        }
        .navigation {
            .colors( #FFF, @ln-blue, darken(@ln-blue, 20%))
        }
        .page-teaser .arrow {
            display: none;
        }
    }
    &.-offer {
        .page-teaser,
        .page-header.-colored,
        .navigation {
            .offer-colors-mixin
        }
        .navigation {
            .colors( @ln-blue-offer, #f2f2f2, darken(#f2f2f2, 20%))
        }
        .page-header {
            .colors( white;
            @ln-blue;
            #000);
        }
    }
    &.-team {
        .page-teaser,
        .navigation,
        .page-header.-colored {
            .colors( #fafafa;
            @ln-red;
            darken(@ln-red, 15%));
        }
        .page-header {
            .colors( white;
            @ln-red;
            darken(@ln-red, 15%));
        }
    }
    &.-blog {
        .page-teaser,
        .page-header,
        .navigation {
            .blog-colors-mixin;
        }
        .page-header.-colored {
            .blog-colors-mixin;
        }
    }
    &.-contact {
        .navigation,
        .page-teaser,
        .page-header.-colored {
            .colors( @ln-red, @ln-grey-light, darken(@ln-grey-light, 20%))
        }
        .page-header {
            .colors( white;
            @ln-red;
            darken(@ln-red, 20%));
        }
    }
    &.-projects {
        .page-teaser,
        .page-header.-colored,
        .navigation {
            .projects-colors-mixin;
        }
        .page-header {
            .colors( white;
            @ln-blue;
            #000);
        }
    }
    &.-events {
        .page-teaser,
        .page-header.-colored,
        .navigation {
            .events-colors-mixin;
        }
        .page-header {
            .colors( white;
            @ln-turkoise;
            #000);
        }
    }
    &.-newsletter {
        .page-teaser,
        .page-header.-colored,
        .navigation {
            .newsletter-colors-mixin;
        }
        .page-header {
            .colors( white;
            #011A33;
            #000);
        }
    }

    &.-container {
        .page-teaser,
        .page-header.-colored,
        .navigation {
            .newsletter-colors-mixin;
        }
        .page-header {
            .colors( white;
            #011A33;
            #000);
        }
    }

    &.-project {
        .page-header.-colored,
        .navigation {
            .project-colors-mixin;
        }
        .page-header {
            .colors( white;
            @ln-blue;
            #000);
        }
        .page-teaser {
            .colors(@ln-grey-light, @ln-blue, #000);
        }
    }
}

@media only screen and (max-width: @medium-break-point) {
    /* For mobile phones: */
    .page-main {
        .page-fluid {
            padding: @mobile-page-padding;
        }

        >.coffee {
            right: 20px;
        }
        >.content {
            padding-top: rem(75px);
            padding-bottom: rem(5px);
            min-height: 100vh;
            >.bottom-175 {
                margin-bottom: rem(75px);
            }
        }
    }
}

@media screen and (orientation:landscape) and (max-device-width: 780px) {
    .page-main {
        .page-fluid {
            padding: @mobile-page-padding;
        }

        >.coffee {
            right: 20px;
        }
        >.content {
            padding-top: rem(75px);
            padding-bottom: rem(5px);
            min-height: 100vh;
            >.bottom-175 {
                margin-bottom: rem(75px);
            }
        }
    }
}