/*
---
name: Main button
category: Buttons/Main
tag:
 - components
compile: true
---

```jade
button.button-main My button
```
*/
.button-main {
	background-color: #123456;
    border: 0;
    color: @text-inverted-color;
    cursor: pointer;
    margin: 0;
    padding: rem( 5px ) rem( 10px );
    text-decoration: none;
    border-radius: @border-radius;
    font-size: rem( 16px );
}