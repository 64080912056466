/*
---
name: Socialmedia buttons
category: Buttons/Socialmedia
tag:
 - components
compile: true
---

```jade
.socialmedia f
```
*/

.socialmedia {
    font-size: rem(23px);
    a {
        margin-right: 10px;
    }
}