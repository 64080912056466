 .item-navigation {
    width: 100%;
    text-align: right;
    margin-bottom:15px;
    > a {
        color:@ln-red; 
        line-height:rem(22px);
        vertical-align: top;
        &:hover {
            color:darken(@ln-red,20%);
        }
    }
    > a.arrow {
        font-size: rem(21px);
    }

}