/*
---
name: .list-project
category: Lists/Project
tag:
 - components
compile: true
---

```jade
div.list-project
	div.pic.pic-overlay
		img.responsive(src="http://placehold.it/350x150")
		div.overlay Overlay content
	h4.name Projektname
	div.description Kundenname
```
*/

.-events {
    .list-event {
        margin: 0 0 rem(40px) 0;
        position: relative;
        >a {
            >.pic {
                width: 100%;
                height: 340px;
                background-repeat: no-repeat;
                background-position: center center;
            }
            >.name {
                font-weight: bold;
                margin: rem( 5px) 0;
                height:rem(40px);
            }
            >.description {
                font-size: rem( 17px);
                line-height: rem( 25px);
                font-family: "Akkurat-Light";
                letter-spacing: rem(0.16px);
                margin-top: rem( 10px);
            }
            .overlay {
                color: white;
                padding: 20px;
                font-size: rem(25px);
                line-height: rem(33px);
                font-family: "Akkurat-Bold";
                >.more {
                    float: right;
                    position: absolute;
                    bottom: 15px;
                    right: 35px;
                    font-size: rem(15px);
                    >.icon-arrow-long {
                        position: relative;
                        top: 4px;
                        left: 5px;
                    }
                }
            }
            &:hover {
                >.pic {
                    transition: 0.8s;
                    filter: grayscale(80%);
                }
            }
        }
    }
    .-event {
        h1.title {
            margin-top: rem( 90px);
        }
        p.teaser {
            display: inline;
        }
        .form-elements {
            margin-top: rem( 45px );
            padding-left: rem( 78px );
            h3 {
                text-transform: uppercase;
                letter-spacing: 2px;
                
            }
        }
    }
}

@media only screen and (max-width: @medium-break-point) {
    /* For mobile phones: */
    .list-project {
        >a {
            >.name {
                font-size: rem( 20px);
                margin: rem( 5px) 0;
            }
            >.description {
                font-size: rem( 14px);
            }
        }
    }
.-events {
    .-event {
        .content-element {
            p , h3{
                padding-left: rem( 0px );
            }
        }
        .form-elements {
            margin-top: rem( 45px );
            padding-left: rem( 0px );
        }
    }
    }
}