.offer-icon {
    width: rem(130px);
    margin-left: rem(35px);

    &.-narrow{
        width: rem(100px);
        margin-left: rem(50px);
    }

    &.-wide {
        width: rem( 180px );
        margin-left: rem( 10px );
    }
   
}
@media only screen and (max-width: @medium-break-point) {
    /* For mobile phones: */
    .offer-icon {
        width: rem(130px);
        margin-left: 0;
    
        &.-narrow{
            width: rem(100px);
            margin-left: 0;
        }
    
        &.-wide {
            width: rem( 180px );
            margin-left:0;
        }
       
    }
}