.-projects {
    
    .projects-container {
        margin-bottom: rem(135px);
    }

}

@media screen and (max-width: @medium-break-point) {

    .-projects {
        .projects-container {
            margin-bottom: rem(45px);
        }
    }
}


@media screen  and (max-width: @small-break-point) {
    .-projects {
        .teaser {
            font-size: rem(30px);
            line-height: rem(40px);
            letter-spacing: rem(0.33);
            margin-top: rem(35px);
            margin-bottom: rem(20px);
        }


    }
}