.page-main {
    &.-start {
        overflow-x: hidden;
        @keyframes spaceship {
            0%   {
                top:15%
            }
            25%  {
                
                }
            50%  {
                
                top:10%;
                }
            75%  {
                
                    }
            100%  {
                top:15%;
                
                }
        }
        @keyframes framesize {
            0%   {
                transform:scale(0);
                }
            100% {
                transform:scale(1);
                }
            }
            
            .spaceship {
                position:absolute;
                width:200px;
                right:-200px;
                top: 20%;
                z-index:50;
                cursor:pointer;
                transition:all 10s;
                animation-name: spaceship;
                animation-duration: 10s;
                animation-iteration-count: infinite;
                img {
                    width:100%;
                }
            }
            &:hover .spaceship {
                right:50%;

            }
       
        .value-animation {
            display:none;
            position: absolute;
            top:0px;
            left:0px;
            z-index:100;
            width:100vw;
            height:100vh;
            max-width:100%;
            overflow:hidden;
            &.-open {
                display:block;
            }
            .frame {
                width:100vw;
                height:100vh;
                max-width:100%;
                border:none;
                animation-name:framesize;
                animation-duration:0.5s;
                #page {
                    margin:0;
                }
            }
        }
    }
    @media only screen and (max-width: @medium-break-point) {
        /* For mobile phones: */
        .spaceship {
            display: none;
        }
    }
    
}