@charset "UTF-8";
/*
---
name: .stretch
category: Shared/Helpers
tag:
 - helpers
---

`.stretch` Define an element which stretches to the size of its parent

```html
<div class="rel" style="height: 55px;">
   <div class="stretch styleguide-grid-unit"> I'm stretched to the size of my parent</div>
</div>
```
*/
.stretch {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/*
---
name: .abs
category: Shared/Helpers
tag:
 - helpers
---

`.abs` Set the element position to absolute
*/
.abs {
  position: absolute;
}
/*
---
name: .rel
category: Shared/Helpers
tag:
 - helpers
---

`.rel` Set the element position to relative
*/
.rel {
  position: relative;
}
/*
---
name: .vcenter
category: Shared/Helpers
tag:
 - helpers
---

`.vcenter` Sets vertical-align to middle
*/
.vcenter {
  vertical-align: middle;
}
/*
---
name: .hcenter
category: Shared/Helpers
tag:
 - helpers
---

`.hcenter` Sets text-align to center
*/
.hcenter {
  text-align: center;
}
/*
---
name: .floatleft
category: Shared/Helpers
tag:
 - helpers
---

`.floatleft` Sets float to left
*/
.floatleft {
  float: left;
}
/*
---
name: .floatright
category: Shared/Helpers
tag:
 - helpers
---

`.floatright` Sets float to right
*/
.floatright {
  float: right;
}
/*
---
name: .hidden
category: Shared/Helpers
tag:
 - helpers
---

`.hidden` Sets display to none.
*/
.hidden {
  display: none;
}
/*
---
name: rem( pixels )
category: Shared/Mixins
template: mixin
tag:
 - mixin
parameters:
 - name: pixels
   description: The pixel value to convert into rem based on the 16px root font-size
   optional: false
---

This less function turns a pixel value into a rem value based on the root font-size set to 16px.

An example usage looks like this:

```less
.example {
	padding: rem( 15px ) rem( 12px )
}
```
*/
/*
---
name: .for( @array, @code )
category: Shared/Mixins
template: mixin
tag:
 - mixin
parameters:
 - name: array
   description: The array of values to loop over
   optional: false
 - name: code
   description: The code that is executed on every value. You can access the index with `@i` and the value with `@value`
   optional: false
---

This mixin allows to execute code over an array

An example usage looks like this:
```less
@colors: #1abc9c, #2ecc71, #3498db, #9b59b6;

.for(@colors, {
    .color-@{i} {
        color: @value;
    }
});
```

This generates:
```css
.color-1 {
  color: #1abc9c;
}
.color-2 {
  color: #2ecc71;
}
.color-3 {
  color: #3498db;
}
.color-4 {
  color: #9b59b6;
}
```
*/
/*
---
name: Global Reset
category: Shared
---

The lernetz css library has a set of reset style to have the same behaviour on every browser.
Furthermore this reset sets some global variables like:
 * body font-families
 * body line-height
 * body text-rendering
 * :root font-size for proper rem conversion
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: inherit;
  vertical-align: baseline;
}
:root {
  font-size: 16px;
}
html {
  font-size: 100%;
}
body {
  line-height: inherit;
  color: #000;
  font-family: "Akkurat-Regular";
  text-rendering: optimizeLegibility;
  background-color: #fff;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
select {
  vertical-align: middle;
}
textarea {
  width: 100%;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
/*
 * Normalize the behaviour of all browsers
 *
 * Instead of setting box-sizing fix on all elements we only set it on the root element
 * and tell all elements to inherit it's property from the parent
 * That way it's possible to overwrite the box-sizing anywhere while keeping proper inheritance
 */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
a {
  text-decoration: none;
  color: #ec6449;
}
a > img {
  display: block;
}
/*
---
name: Colors
category: Shared/Variables
template: colors
tag:
 - variables
 - colors
colors:
 - variable: "@text-color"
   description: "The default text color on the body"
   value: "#3F3F40"
 - variable: "@text-inverted-color"
   description: "The default inverted text color on the body"
   value: "#FFF"
 - variable: "@action-color"
   description: "The main action color used for links and buttons"
   value: "#EC6449"
 - variable: "@body-color"
   description: "The main background color"
   value: "#FFF"
---

The color variables that are used throughout the library. 
*/
/*
---
name: Global settings
category: Shared/Variables
template: variables
tag:
 - variables
variables:
 - name: "@text-line-height"
   description: "The line height of the body"
   default: "1.5rem"
 - name: "@font-family"
   description: "The font-family of the body"
   default: "'Helvetica', sans-serif"
 - name: "@text-rendering"
   description: "The text-rendering of the body"
   default: "optimizeLegibility"
 - name: "@transition-duration"
   description: "The duration of the slide transitions"
   default: "0.4s"
---

These variables setups some global text formatting and styling.
*/
/*
---
name: Size settings
category: Shared/Variables
template: variables
tag:
 - variables
 - grid
variables:
 - name: "@grid-gutter"
   description: "The space between the grid children"
   default: "rem( 30px )"
 - name: "@grid-break-points"
   description: "The screensize when the grid should break."
   default: "rem(600px), rem(1024px)"
 - name: "@grid-break-labels"
   description: "The breakpoint label corresponding to the entie in the `@grid-break-points` list"
   default: "medium, large"
 - name: "@page-padding"
   description: "The padding for the .page-fluid component"
   default: "rem( 60px )"
---

These variables setups some global sizes mainly for the grid.
*/
/*
---
name: .button-default
category: Buttons/Default
tag:
 - component
 - button
---

The default button sets some basic properties so it is working as a `button` or `a` tag.

An example for a button:
```jade
button.button-default My Button
```

An example for a link:
```jade
a.button-default My Link
```
*/
.button-default {
  appearance: none;
  background: transparent;
  border: none;
  display: inline-block;
  cursor: pointer;
  outline: none;
}
/*
---
name: .grid
category: Layout/Grid
tag:
 - component
 - responsive
compile: true
---

A simple grid system that wraps children nodes based on the viewport width.

## Simple side by side rendering

The easiest use case is to use simple `.col-1`, `.col-1-2`, `.col-1-3`, `.col-1-4` for a side-by-side rendering.
The default value for all child elements of a `.grid` is equivalent to `.col-1`, so for only one child it will span the full width.

```jade
div.grid
	div.col-1-2 
		div.aigis-debug col-1-2
	div.col-1-2
		div.aigis-debug col-1-2
```
```jade
div.grid
	div.col-1-4 
		div.aigis-debug col-1-4
	div.col-3-4
		div.aigis-debug col-3-4
```

## Layouts on different devices

The breakpoints for different screen sizes are specified in the `@grid-break-points`, `@grid-break-labels` variables.
The default values define two breakpoints with labels: `.medium-X-X` and `.large-X-X`.
So an example grid with two breakpoints looks like this:

```jade
div.grid
	div.medium-1-2.large-1-4.xlarge-1-2
		div.aigis-debug col-1 medium-1-2 large-1-4
	div.medium-1-2.large-1-4.xlarge-1-2
		div.aigis-debug col-1 medium-1-2 large-1-4
	div.col-1-2.medium-1-2.large-1-4.xlarge-1-2
		div.aigis-debug col-1-2 medium-1-2 large-1-4
	div.col-1-2.medium-1-2.large-1-4.xlarge-1-2
		div.aigis-debug col-1-2 medium-1-2 large-1-4
```

**Note:** The `col-1` classes are not present on the first two nodes, since this is the default behaviour.


## Reverse order

To reverse the order in which the columns are displayed, you can add the modifier class `.-reverse` to your grid.
The columns will be in DOM order when viewport switches to small layout.

```jade
div.grid.-reverse
	div.col-1-4
		div.aigis-debug First column in DOM
	div.col-1-4
		div.aigis-debug Second column in DOM
	div.col-1-4
		div.aigis-debug Third column in DOM
	div.col-1-4
		div.aigis-debug Fourth column in DOM
```
## No gutter

If you dont need a spacing/gutter between the grid columns you can use the modifier `.-nogutter`.

```jade
div.grid.-nogutter
	div.col-1-4
		div.aigis-debug First column in DOM
	div.col-1-4
		div.aigis-debug Second column in DOM
	div.col-1-4
		div.aigis-debug Third column in DOM
	div.col-1-4
		div.aigis-debug Fourth column in DOM
```
*/
.grid {
  margin: 0 -0.9375rem;
  /**
	 * Generate all media breakpoints based on the @grid-break-points, @grid-break-labels variables.
	 */
}
.grid:after {
  content: "";
  display: table;
  clear: both;
}
.grid > * {
  float: left;
  position: relative;
  width: 100%;
  padding: 0 0.9375rem;
}
.grid.-reverse > * {
  float: right;
}
.grid.-nogutter {
  margin: 0;
}
.grid.-nogutter > * {
  padding: 0;
}
.grid .col-1 {
  width: 100%;
}
.grid .col-1-2 {
  width: 50%;
}
.grid .col-1-3 {
  width: 33.33333333%;
}
.grid .col-2-3 {
  width: 66.66666667%;
}
.grid .col-1-4 {
  width: 25%;
}
.grid .col-3-4 {
  width: 75%;
}
@media all and (min-width: 37.5rem) {
  .grid > .medium-1 {
    width: 100%;
  }
  .grid > .medium-1-2 {
    width: 50%;
  }
  .grid > .medium-1-3 {
    width: 33.33333333%;
  }
  .grid > .medium-2-3 {
    width: 66.66666667%;
  }
  .grid > .medium-1-4 {
    width: 25%;
  }
  .grid > .medium-3-4 {
    width: 75%;
  }
}
@media all and (min-width: 64rem) {
  .grid > .large-1 {
    width: 100%;
  }
  .grid > .large-1-2 {
    width: 50%;
  }
  .grid > .large-1-3 {
    width: 33.33333333%;
  }
  .grid > .large-2-3 {
    width: 66.66666667%;
  }
  .grid > .large-1-4 {
    width: 25%;
  }
  .grid > .large-3-4 {
    width: 75%;
  }
}
/*
---
name: img.responsive
category: Layout/Image
tag:
 - responsive
compile: true
---

This class makes the image scaling to its max width.

```jade
img.responsive(src='http://www.byui.edu/images/agriculture-life-sciences/flower.jpg')
```
*/
img.responsive {
  max-width: 100%;
  height: auto;
}
/*
---
name: .media-responsive
category: Layout/Media
tag:
 - responsive
compile: true
---

`.media-responsive` - This makes a the `.media-responsive` div a container for any media element (iframe, video, ... ) that responsive scales with a fix ratio.
The media element ( child ) needs the class `.stretch` to fully fill out the parents size.

The `.media-responsive` component has to predefined modifiers: `.-media-4-3`, `.-media-16-9` that define a fix ratio.

```jade
div.media-responsive.-media-16-9
	iframe.stretch(src="https://www.youtube.com/watch?v=TCd6PfxOy0Y")
```
*/
.media-responsive {
  width: 100%;
  position: relative;
  padding-bottom: 50%;
}
.media-responsive.-media-4-3 {
  padding-bottom: 75%;
}
.media-responsive.-media-16-9 {
  padding-bottom: 56.26%;
}
/*
---
name: .page-fluid
category: Page/Fluid
tag:
 - responsive
compile: true
---

`.page-fluid` centers the page content and adds a padding so the page sticks not to the browser viewport.
Furthermore it has a max-width based on the last `@grid-break-points` value.

```jade
div.page-fluid
	div.aigis-debug Some page content
```

## Max-width page

To broaden the width of the page you can add the modifier `.-unlimited`. Thsi will span the page to the full width.

```jade
div.page-fluid.-unlimited
	div.aigis-debug Some page content
```
*/
.page-fluid {
  max-width: 70.25rem;
  margin: auto;
  padding: 0 3.125rem;
}
.page-fluid.-unlimited {
  max-width: none;
}
/*
---
name: .row
category: Layout/Row
tag:
 - component
compile: true
---

This styling allow to place containers in a row. They will **not** break as the `.grid` but resize them accordingly.
Some benefits of the `.row` approach is the easy vertical centering and the usage of the `.col-auto` class.

## Basic row with vertical centering

You can use the sizing classes `.col-X-X` to define the width of a column as the following example shows. 

```jade
div.row
	div.col-1-4.aigis-debug col-1-4 with a lot of text that spans the column.
	div.col-3-4.aigis-debug col-3-4 content.
```

## Fixed row

There is a `.-fixed` modifier where each column has the same width.

```jade
div.row.-fixed
	div.aigis-debug evenly spreadout column
	div.aigis-debug evenly spreadout column with more content
	div.aigis-debug evenly spreadout column
	div.aigis-debug evenly spreadout column with much more content than the other
	div.aigis-debug.vcenter evenly spreadout column with .vcenter
```


## Auto sized column width and vcenter

There is a special `.col-auto` class that resizes the column to the size of its content.
This is usefull for example if you have a fixed size logo and the rest of the header has to resize.

```jade
div.row.-center
	div.col-auto.aigis-debug
		img(src="http://placehold.it/100x100")
	div.col-1.aigis-debug The rest
	div.col-auto.aigis-debug
		img(src="http://placehold.it/200x100")
```
*/
.row {
  display: table;
  width: 100%;
}
.row.-fixed {
  table-layout: fixed;
}
.row.-center > * {
  vertical-align: middle;
}
.row.-bottom > * {
  vertical-align: bottom;
}
.row.-padded > * + * {
  padding-left: 1.875rem;
}
.row > * {
  display: table-cell;
}
.row > *.col-1 {
  width: 100%;
}
.row > *.col-1-2,
.row > *.col-2-4 {
  width: 50%;
}
.row > *.col-1-3 {
  width: 33.33333333%;
}
.row > *.col-2-3 {
  width: 66.66666667%;
}
.row > *.col-1-4 {
  width: 25%;
}
.row > *.col-3-4 {
  width: 75%;
}
.row > *.col-auto {
  width: auto;
  white-space: nowrap;
}
/*
---
name: Colors
category: Shared/Variables
template: colors
tag:
 - variables
 - colors
colors:
 - variable: "@text-color"
   description: "The default text color on the body"
   value: "#3F3F40"
 - variable: "@text-color-inverted"
   description: "The default inverted text color on the body"
   value: "#FFF"
 - variable: "@action-color"
   description: "The main action color used for links and buttons"
   value: "#EC6449"
 - variable: "@border-color"
   description: "The color for the border"
   value: "#223322"
---

The color variables that are used throughout the library. 
*/
/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * lernetz.ch
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2016
 */
/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The fonts folder(s) should be placed relative to the regular CSS file.
 *
 * You can use either the complete or subsetted fonts:
 * If you don’t require the entire range of characters, you can use the smaller, subsetted webfonts instead.
 * See "Glyphs & Languages" for an overview of the characters in the *_subsetted.html file in the root directory of this package.
 *
 */
/* Complete */
@font-face {
  font-family: "Akkurat-Light";
  src: url("fonts/lineto-akkurat-light.eot");
  src: url("fonts/lineto-akkurat-light.eot?#iefix") format("embedded-opentype"), url("fonts/lineto-akkurat-light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Akkurat-Regular";
  src: url("fonts/lineto-akkurat-regular.eot");
  src: url("fonts/lineto-akkurat-regular.eot?#iefix") format("embedded-opentype"), url("fonts/lineto-akkurat-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Akkurat-Lightitalic";
  src: url("fonts/lineto-akkurat-lightitalic.eot");
  src: url("fonts/lineto-akkurat-lightitalic.eot?#iefix") format("embedded-opentype"), url("fonts/lineto-akkurat-lightitalic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Akkurat-Italic";
  src: url("fonts/lineto-akkurat-italic.eot");
  src: url("fonts/lineto-akkurat-italic.eot?#iefix") format("embedded-opentype"), url("fonts/lineto-akkurat-italic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Akkurat-Bold";
  src: url("fonts/lineto-akkurat-bold.eot");
  src: url("fonts/lineto-akkurat-bold.eot?#iefix") format("embedded-opentype"), url("fonts/lineto-akkurat-bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Akkurat-Bolditalic";
  src: url("fonts/lineto-akkurat-bolditalic.eot");
  src: url("fonts/lineto-akkurat-bolditalic.eot?#iefix") format("embedded-opentype"), url("fonts/lineto-akkurat-bolditalic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* first color: second color: third color: */
.home-colors-mixin {
  background-color: #011a33;
  color: #f3f3f2;
}
.home-colors-mixin a {
  color: #f3f3f2;
}
.home-colors-mixin a:hover {
  color: #003160;
}
.home-colors-mixin .logo path {
  fill: #f3f3f2;
}
.project-colors-mixin {
  background-color: #f3f3f2;
  color: #003160;
}
.project-colors-mixin a {
  color: #003160;
}
.project-colors-mixin a:hover {
  color: #003160;
}
.project-colors-mixin .logo path {
  fill: #003160;
}
.projects-colors-mixin {
  background-color: #f3f3f2;
  color: #003160;
}
.projects-colors-mixin a {
  color: #003160;
}
.projects-colors-mixin a:hover {
  color: #003160;
}
.projects-colors-mixin .logo path {
  fill: #003160;
}
.events-colors-mixin {
  background-color: #00aecb;
  color: #f3f3f2;
}
.events-colors-mixin a {
  color: #f3f3f2;
}
.events-colors-mixin a:hover {
  color: #f3f3f2;
}
.events-colors-mixin .logo path {
  fill: #f3f3f2;
}
.team-colors-mixin {
  background-color: white;
  color: #ec6449;
}
.team-colors-mixin a {
  color: #ec6449;
}
.team-colors-mixin a:hover {
  color: #d33516;
}
.team-colors-mixin .logo path {
  fill: #ec6449;
}
.newsletter-colors-mixin {
  background-color: #011A33;
  color: white;
}
.newsletter-colors-mixin a {
  color: white;
}
.newsletter-colors-mixin a:hover {
  color: #d9d9d9;
}
.newsletter-colors-mixin .logo path {
  fill: white;
}
.offer-colors-mixin {
  background-color: #011A33;
  color: #f3f3f2;
}
.offer-colors-mixin a {
  color: #f3f3f2;
}
.offer-colors-mixin a:hover {
  color: #f3f3f2;
}
.offer-colors-mixin .logo path {
  fill: #f3f3f2;
}
.contact-colors-mixin {
  background-color: #ec6449;
  color: #d9d9da;
}
.contact-colors-mixin a {
  color: #d9d9da;
}
.contact-colors-mixin a:hover {
  color: #f3f3f2;
}
.contact-colors-mixin .logo path {
  fill: #d9d9da;
}
.blog-colors-mixin {
  background-color: #3f3f40;
  color: #f3f3f2;
}
.blog-colors-mixin a {
  color: #f3f3f2;
}
.blog-colors-mixin a:hover {
  color: #f3f3f2;
}
.blog-colors-mixin .logo path {
  fill: #f3f3f2;
}
.center-vertical-mixin {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.center {
  display: block;
  margin: 0 auto;
}
/*
---
name: Global settings
category: Shared/Variables
template: variables
tag:
 - variables
variables:
 - name: "@text-line-height"
   description: "The line height of the body"
   default: "1.5rem"
 - name: "@font-family"
   description: "The font-family of the body"
   default: "'Helvetica', sans-serif"
 - name: "@text-rendering"
   description: "The text-rendering of the body"
   default: "optimizeLegibility"
---

These variables setups some global text formatting and styling.
*/
/*
---
name: Size settings
category: Shared/Variables
template: variables
tag:
 - variables
 - grid
variables:
 - name: "@grid-gutter"
   description: "The space between the grid children"
   default: "rem( 30px )"
 - name: "@grid-break-points"
   description: "The screensize when the grid should break."
   default: "rem(600px), rem(1024px)"
 - name: "@grid-break-labels"
   description: "The breakpoint label corresponding to the entie in the `@grid-break-points` list"
   default: "medium, large"
 - name: "@page-padding"
   description: "The padding for the .page-fluid component"
   default: "rem( 60px )"
---

These variables setups some global sizes mainly for the grid.
*/
a {
  color: #000000;
}
.paragraph-element a {
  text-decoration: underline;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Akkurat-Bold";
}
h1 {
  font-size: 2.8125rem;
  letter-spacing: 0.030625rem;
  line-height: 2.8125rem;
}
h2 {
  font-size: 2.25rem;
  letter-spacing: 0.105625rem;
  line-height: 2.875rem;
  text-transform: uppercase;
  margin-bottom: 1.25rem;
}
h3 {
  font-size: 1.875rem;
  font-family: "Akkurat-Regular";
  line-height: 2.375rem;
}
h4 {
  font-size: 1.5625rem;
  line-height: 2.0625rem;
}
b,
strong {
  font-family: "Akkurat-Bold";
}
p,
ul,
ol {
  font-size: 1.3125rem;
  font-family: "Akkurat-Light";
  line-height: 2.125rem;
}
p.lead,
.lead > p {
  font-size: 1.875rem;
  margin-bottom: 4.375rem;
  line-height: 2.375rem;
  font-family: "Akkurat-Regular";
}
.small > p {
  font-size: 1.0625rem;
  font-family: "Akkurat-Regular";
  line-height: 1.625rem;
  letter-spacing: 0.19px;
}
@media screen and (max-width: 600px) {
  h1 {
    font-size: 2.5625rem;
    letter-spacing: 0.028125rem;
    line-height: 3.375rem;
  }
  h2 {
    font-size: 2rem;
    letter-spacing: 0.09375rem;
    line-height: 2.75rem;
  }
  h3 {
    font-size: 1.75rem;
    line-height: 2.375rem;
  }
  h4 {
    font-size: 1.4375rem;
    line-height: 2.0625rem;
  }
  p,
  ul,
  ol {
    font-size: 1.25rem;
    line-height: 1.9375rem;
  }
}
@media screen and (max-width: 400px) {
  h1 {
    font-size: 1.875rem;
    letter-spacing: 0.019375rem;
    line-height: 2.5rem;
  }
  h2 {
    font-size: 1.625rem;
    letter-spacing: 0.07625rem;
    line-height: 2.25rem;
  }
  h3 {
    font-size: 1.4375rem;
    line-height: 1.9375rem;
  }
  h4 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  p,
  ul,
  ol {
    font-size: 1.0625rem;
    line-height: 1.6875rem;
  }
}
@keyframes arrow-up-down {
  0% {
    bottom: 3.75rem;
  }
  50% {
    bottom: 1.875rem;
  }
  100% {
    bottom: 3.75rem;
  }
}
@keyframes arrow-up-down-small {
  0% {
    bottom: 1.875rem;
  }
  50% {
    bottom: 0.625rem;
  }
  100% {
    bottom: 1.875rem;
  }
}
.abstract-footer {
  background-color: #f2f2f2;
  padding: 2.5rem 0;
  margin-top: -6.25rem;
}
.abstract-footer .contact {
  margin-top: 1.5rem;
}
.abstract-footer .client p {
  margin-bottom: 1.1875rem;
}
.abstract-footer .client h4 {
  margin-bottom: 0.25rem;
}
.abstract-footer .client.-left {
  clear: left;
  padding-top: 1.5rem;
}
.abstract-footer .photo {
  float: left;
  border-radius: 100%;
  width: 129px;
  height: 129px;
  margin-right: 1.9375rem;
}
@media screen and (max-width: 600px) {
  .abstract-footer {
    text-align: center;
    margin-top: 0;
  }
  .abstract-footer .photo {
    float: none;
    margin-bottom: 0.9375rem;
  }
  .abstract-footer .contact {
    margin-bottom: 0.9375rem;
  }
}
/*
---
name: Close button
category: Buttons/Coffee
tag:
 - components
compile: true
---

```jade
button.button-close 
```
*/
.button-close {
  position: absolute;
  top: 1.875rem;
  right: 3.125rem;
  border: 0;
  border-radius: 100%;
  width: 1.3125rem;
  height: 1.3125rem;
  cursor: pointer;
  margin: 0;
}
.button-close:before {
  font-size: 1.3125rem;
}
@media only screen and (max-width: 600px) {
  .button-close {
    right: 1.25rem;
  }
}
/*
---
name: Coffee button
category: Buttons/Coffee
tag:
 - components
compile: true
---

```jade
button.button-coffee Gimme Coffee
```
*/
.button-coffee {
  height: 55px;
  width: 55px;
  color: white;
  background-color: #ec6449;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.3);
  border-radius: 100%;
  display: inline-block;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.button-coffee:hover {
  transform: scale(1.1, 1.1);
}
.-contact .button-coffee {
  visibility: hidden;
}
/*
---
name: Main button
category: Buttons/Main
tag:
 - components
compile: true
---

```jade
button.button-main My button
```
*/
.button-main {
  background-color: #123456;
  border: 0;
  color: #FFF;
  cursor: pointer;
  margin: 0;
  padding: 0.3125rem 0.625rem;
  text-decoration: none;
  border-radius: 4px;
  font-size: 1rem;
}
/*
---
name: Socialmedia buttons
category: Buttons/Socialmedia
tag:
 - components
compile: true
---

```jade
.socialmedia f
```
*/
.socialmedia {
  font-size: 1.4375rem;
}
.socialmedia a {
  margin-right: 10px;
}
.form-group {
  margin: 0 0 0.9375rem 0;
  font-size: 1rem;
  /*
        &.-full {
            width:100%;
        }
        &.-half {
            width:49%;
        }
        */
}
.form-group label {
  display: block;
  font-size: 0.8125rem;
  margin-bottom: 0.1875rem;
}
.form-group .help-block {
  color: red;
  font-size: 1rem;
  padding: 0.625rem;
}
.form-group .input {
  line-height: 2.5rem;
  padding: 0 0.3125rem;
  width: 50%;
  border: 2px solid black;
}
.form-group input[type=submit] {
  background: transparent;
  cursor: pointer;
  padding: 5px 20px 3px 20px;
  border: 2px solid #000000;
  border-radius: 100px;
  font-size: 0.875rem;
  text-transform: uppercase;
}
.form-group textarea {
  line-height: 20px;
  padding: 0 0.3125rem;
  width: 50%;
  height: 8em;
  border: 2px solid black;
}
@media screen and (max-width: 600px) {
  .form-group .input {
    width: 100%;
  }
}
.image-container {
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
}
.image-container .image {
  margin-top: 1.875rem;
}
.image-container .caption {
  padding: 0;
  padding-left: 0.9375rem;
  font-size: 0.9375rem;
  font-family: "Akkurat-Light";
  line-height: 1.4375rem;
  letter-spacing: 0.01rem;
  margin-top: 0.6875rem;
}
@media screen and (max-width: 1024px) {
  .image-container {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .image-container .caption {
    font-size: 0.8125rem;
    line-height: 1.3125rem;
    letter-spacing: 0.00875rem;
    margin-top: 0.4375rem;
  }
}
@media screen and (max-width: 400px) {
  .image-container {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .image-container .image {
    margin-top: 1.25rem;
    padding: 0;
  }
  .image-container .caption {
    font-size: 0.6875rem;
    letter-spacing: 0.0075rem;
    line-height: 1.0625rem;
    padding: 0;
  }
}
.link-element {
  margin-top: 0.625rem;
  font-family: "Akkurat-light";
  font-size: 1.3125rem;
}
.link-element .description {
  font-family: "Akkurat-Bold";
}
.link-element a:hover {
  color: #003160;
}
.link-element.-arrow a:before {
  content: url(../images/arrow_right.svg);
  margin-right: 1rem;
}
.link-element.-button a {
  font-family: "Akkurat-Regular";
  font-weight: 400;
  background: transparent;
  cursor: pointer;
  padding: 5px 20px 3px 20px;
  border: 2px solid #000000;
  border-radius: 100px;
  font-size: 0.875rem;
  text-transform: uppercase;
}
.paragraph-element > a {
  color: black;
  border-bottom: 2px solid black;
  padding-bottom: 2px;
}
.paragraph-element > a:hover {
  color: #003160;
}
.paragraph-element > a:visited {
  color: black;
}
.paragraph-element.lead > p {
  margin-bottom: 4.375rem;
}
.paragraph-element ul,
.paragraph-element ol {
  margin-top: 1.25rem;
  margin-left: 1.3125rem;
}
@media screen and (max-width: 600px) {
  .paragraph-element.lead > p {
    font-size: 1.75rem;
    margin-bottom: 3.125rem;
  }
}
@media screen and (max-width: 400px) {
  .paragraph-element.lead > p {
    font-size: 1.4375rem;
    margin-bottom: 2.1875rem;
  }
}
.quote-element {
  margin: 0 0 1.5rem 0;
  text-align: center;
}
.quote-element .quote {
  quotes: "«" "»";
}
.quote-element .quote > p {
  display: inline;
  padding: 0;
}
.quote-element .quote:before {
  font-size: 1.3125rem;
  font-family: "Akkurat-Light";
  line-height: 2.125rem;
  content: open-quote;
}
.quote-element .quote:after {
  font-size: 1.3125rem;
  font-family: "Akkurat-Light";
  line-height: 2.125rem;
  content: close-quote;
}
.quote-element .author {
  margin-top: 0.625rem;
}
.quote-element .author > p {
  font-style: italic;
  text-align: center;
  padding: 0;
}
.content-element .quote-element + .content-element .quote-element {
  margin-top: 4.6875rem;
  border: 1px solid black;
}
.titel-element {
  margin-bottom: 0.625rem;
}
.titel-element h1 {
  margin-bottom: 2.8125rem;
}
.titel-element h2 {
  margin-top: 2.8125rem;
  margin-bottom: 1.25rem;
}
.titel-element h3 {
  margin-top: 2.8125rem;
  margin-bottom: 0.625rem;
}
.titel-element h4 {
  margin-top: 1rem;
  margin-bottom: 0.625rem;
}
.titel-element .-first {
  margin-top: 4.375rem;
}
.titel-element .-last {
  padding-top: 3.125rem;
  margin-top: 6.25rem;
  border-top: 1px solid #b3b3b5;
  text-align: center;
}
@media screen and (max-width: 600px) {
  .titel-element {
    margin-bottom: 0;
  }
  .titel-element h1 {
    margin-bottom: 1.875rem;
  }
  .titel-element h2 {
    margin-top: 2.5rem;
    margin-bottom: 0.875rem;
  }
  .titel-element h3 {
    line-height: 2.375rem;
  }
  .titel-element h4 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .titel-element .-first {
    margin-top: 2.8125rem;
  }
  .titel-element .-last {
    padding-top: 1.875rem;
    margin-top: 4.375rem;
  }
}
@media screen and (max-width: 400px) {
  .titel-element h1 {
    line-height: 2.5rem;
    margin-bottom: 1.25rem;
  }
  .titel-element h2 {
    margin-top: 1.875rem;
    margin-bottom: 0.625rem;
  }
  .titel-element h3 {
    line-height: 1.9375rem;
  }
  .titel-element h4 {
    margin-top: 1.25rem;
    margin-bottom: 0.375rem;
  }
  .titel-element.-last {
    padding-top: 1.25rem;
    margin-top: 3.125rem;
  }
}
/*
---
name: Lernetz Logo
category: Components/Logo
tag:
 - components
---

```html
<svg width="166px" height="30px" viewBox="0 0 166 30" class="image-lnlogo">
    <g>
        <path d="M23.5049332,15.806 L23.5049332,25.6907778 L37.3546374,25.6907778 C38.3002732,25.6907778 39.0740105,26.4351111 39.0740105,27.3451111 C39.0740105,28.2547222 38.3002732,28.9990556 37.3546374,28.9990556 L21.6257454,28.9990556 C20.6801097,28.9990556 19.9059825,28.2271111 19.9059825,27.2836667 L19.9059825,2.10972222 C19.9059825,1.16627778 20.6801097,0.394333333 21.6257454,0.394333333 L37.3546374,0.394333333 C38.3002732,0.394333333 39.0740105,1.13827778 39.0740105,2.04827778 C39.0740105,2.95788889 38.3002732,3.70222222 37.3546374,3.70222222 L23.5049332,3.70222222 L23.5049332,12.4168333 L35.454011,12.4168333 C36.3996467,12.4168333 37.1733841,13.1794444 37.1733841,14.1112222 C37.1733841,15.0433889 36.3996467,15.806 35.454011,15.806 L23.5049332,15.806 Z" id="Fill-1" fill="#F3F3F2"></path>
        <path d="M53.3956751,13.7482722 L47.472004,13.7482722 L47.472004,3.70210556 L53.3956751,3.70210556 C54.3413108,3.70210556 56.450476,3.73477222 57.3696058,3.95760556 C57.3696058,3.95760556 60.9938931,4.52849444 60.9385426,8.74560556 C60.8251131,12.28255 59.013749,13.1023278 57.1922504,13.5409944 C55.518483,13.8326611 54.3413108,13.7482722 53.3956751,13.7482722 L53.3956751,13.7482722 Z M62.4205323,23.7003278 C62.0662112,22.9241056 61.6744701,22.0732167 61.2862372,21.2343833 L59.0628628,16.4016611 C60.1698725,15.9742722 60.6840083,15.8086056 61.4663211,15.2439389 C62.2552604,14.6749944 62.8368303,14.1737167 63.5162379,13.0649944 C63.5162379,13.0649944 64.779944,11.2422722 64.6587187,8.74560556 C64.6669043,5.94677222 63.4753098,4.33521667 63.4753098,4.33521667 C62.9841717,3.50921667 62.0256728,2.40593889 61.1599445,1.92060556 C59.5298338,0.787772222 57.0686863,0.510883333 57.0686863,0.510883333 C56.1351342,0.359216667 54.3413108,0.394216667 53.3956751,0.394216667 L45.5928162,0.394216667 C44.6471805,0.394216667 43.8730533,1.16616111 43.8730533,2.10960556 L43.8730533,27.28355 C43.8730533,28.2273833 44.6471805,28.9993278 45.5928162,28.9993278 L45.752631,28.9993278 C46.6982667,28.9993278 47.472004,28.2273833 47.472004,27.28355 L47.472004,17.0666611 L55.398427,17.0969944 L55.4151881,17.1304389 C55.4385756,17.2128833 55.4619631,17.2957167 55.4989934,17.3758278 L60.3745134,27.9722722 C60.768983,28.8301611 61.7949159,29.2097167 62.6544075,28.8157722 L62.8426771,28.7298278 C63.7021688,28.3362722 64.0826059,27.3123278 63.6881363,26.4548278 L62.4205323,23.7003278 Z" id="Fill-3" fill="#F3F3F2"></path>
        <path d="M133.21033,3.74258889 L133.21033,27.2835889 C133.21033,28.2270333 132.436593,28.9993667 131.490957,28.9993667 L131.290214,28.9993667 C130.344579,28.9993667 129.570841,28.2270333 129.570841,27.2835889 L129.570841,3.74258889 L122.757665,3.74258889 C121.811639,3.74258889 121.037902,2.98931111 121.037902,2.06842222 C121.037902,1.14753333 121.811639,0.394255556 122.757665,0.394255556 L140.023117,0.394255556 C140.969143,0.394255556 141.74288,1.14753333 141.74288,2.06842222 C141.74288,2.98931111 140.969143,3.74258889 140.023117,3.74258889 L133.21033,3.74258889 Z" id="Fill-5" fill="#F3F3F2"></path>
        <path d="M3.59879483,1.87584444 L3.59879483,25.4164556 L15.669098,25.4164556 C16.6151235,25.4164556 17.3888608,26.1697333 17.3888608,27.0910111 C17.3888608,28.0119 16.6151235,28.7655667 15.669098,28.7655667 L1.71960696,28.7655667 C0.773971255,28.7655667 -0.000155916852,27.9932333 -0.000155916852,27.0497889 L-0.000155916852,1.87584444 C-0.000155916852,0.9324 0.773971255,0.160455556 1.71960696,0.160455556 L1.87942174,0.160455556 C2.82505745,0.160455556 3.59879483,0.9324 3.59879483,1.87584444" id="Fill-7" fill="#F3F3F2" mask="url(#mask-2)"></path>
        <path d="M102.879786,15.8184056 L102.879786,25.7031833 L116.72949,25.7031833 C117.675126,25.7031833 118.448863,26.4475167 118.448863,27.3575167 C118.448863,28.2671278 117.675126,29.0114611 116.72949,29.0114611 L101.000598,29.0114611 C100.054963,29.0114611 99.2808353,28.2395167 99.2808353,27.2960722 L99.2808353,2.12212778 C99.2808353,1.17868333 100.054963,0.40635 101.000598,0.40635 L116.72949,0.40635 C117.675126,0.40635 118.448863,1.15068333 118.448863,2.06068333 C118.448863,2.97029444 117.675126,3.71462778 116.72949,3.71462778 L102.879786,3.71462778 L102.879786,12.4292389 L114.828864,12.4292389 C115.7745,12.4292389 116.548237,13.19185 116.548237,14.1236278 C116.548237,15.0557944 115.7745,15.8184056 114.828864,15.8184056 L102.879786,15.8184056 Z" id="Fill-10" fill="#F3F3F2"></path>
        <path d="M165.446334,3.12526667 C165.628757,2.86782222 165.738289,2.55593333 165.738289,2.21876667 L165.738289,1.99787778 C165.738289,1.1291 165.026139,0.4186 164.155343,0.4186 L145.897869,0.4186 C145.027074,0.4186 144.314534,1.1291 144.314534,1.99787778 L144.314534,2.21876667 C144.314534,3.08754444 145.027074,3.79804444 145.897869,3.79804444 L160.496364,3.79804444 L144.768642,26.1506 C144.748762,26.1782111 144.737068,26.2085444 144.719138,26.2365444 C144.510209,26.5044889 144.380019,26.8362111 144.380019,27.2002111 L144.380019,27.4207111 C144.380019,28.2894889 145.092559,29.0003778 145.963354,29.0003778 L164.099603,29.0003778 C164.970398,29.0003778 165.682549,28.2894889 165.682549,27.4207111 L165.682549,27.2002111 C165.682549,26.3314333 164.970398,25.6205444 164.099603,25.6205444 L149.636755,25.6205444 L165.364478,3.26798889 C165.397221,3.22248889 165.418659,3.17271111 165.446334,3.12526667" id="Fill-12" fill="#F3F3F2"></path>
        <path d="M2.95711902,14.7009333 C4.45470039,16.5337667 8.93419156,21.9817111 8.93419156,21.9817111 L8.94159761,1.87071111 C8.94159761,0.926877778 9.71572478,0.154933333 10.6613605,0.154933333 L10.855477,0.159988889 C11.8011127,0.159988889 12.5748501,0.931933333 12.5748501,1.87537778 L12.5748501,27.0489333 C12.5748501,27.9923778 11.8011127,28.7647111 10.855477,28.7647111 L10.8110407,28.7647111 C10.3686266,28.7604333 10.0295074,28.7059889 9.66076409,28.4434889 C9.40817879,28.2334889 9.41870318,28.2439889 9.26044757,28.0651 C7.09788083,25.4486556 1.00192169,18.0010444 0.143989213,16.9623222 C0.057065568,16.8425444 0.192323437,16.7212111 0.192323437,16.7212111 C0.192323437,16.7212111 2.57707169,14.7728778 2.70297455,14.6671 C2.83550388,14.5628778 2.95711902,14.7009333 2.95711902,14.7009333" id="Fill-14" fill="#F3F3F2" mask="url(#mask-4)" transform="translate(81.466555, 0.233839)"></path><path d="M79.3635489,14.4023056 L73.3658173,7.17169444 L73.3580215,27.2830833 C73.3580215,28.2265278 72.5842841,28.9984722 71.6386484,28.9984722 L71.4445319,28.9938056 C70.4988962,28.9938056 69.7251588,28.2218611 69.7251588,27.2780278 L69.7251588,2.10447222 C69.7251588,1.16102778 70.4988962,0.389083333 71.4445319,0.389083333 L71.4889682,0.389083333 C71.9313823,0.393361111 72.2705015,0.447416667 72.6392448,0.709916667 C72.8918301,0.920305556 72.8813057,0.909805556 73.0391715,1.08830556 C75.2177198,3.72419444 81.3557764,11.1873611 82.1419872,12.1381944 C82.2659411,12.2758611 82.1529013,12.37775 82.1529013,12.37775 C82.1529013,12.37775 80.3668738,13.8395833 79.6188627,14.4423611 C79.4836048,14.5489167 79.376412,14.4104722 79.376412,14.4104722" id="Fill-17" fill="#F3F3F2"></path>
    </g>
</svg>
```
*/
.image-lnlogo.-offer {
  fill: #f3f3f2;
}
.image-lnlogo.-team {
  fill: #ec6449;
}
.image-lnlogo.-blog {
  fill: #f3f3f2;
}
.image-lnlogo.-contact {
  fill: #e3e3e5;
}
.image-lnlogo.-project {
  fill: #003160;
}
.item-navigation {
  width: 100%;
  text-align: right;
  margin-bottom: 15px;
}
.item-navigation > a {
  color: #ec6449;
  line-height: 1.375rem;
  vertical-align: top;
}
.item-navigation > a:hover {
  color: #bb2f14;
}
.item-navigation > a.arrow {
  font-size: 1.3125rem;
}
.link-icon {
  width: 55px;
  fill: #ec6449;
}
.link-icon.-vcard {
  width: 43px;
  margin: 6px;
}
/*
---
name: .list-project
category: Lists/Project
tag:
 - components
compile: true
---

```jade
div.list-project
	div.pic.pic-overlay
		img.responsive(src="http://placehold.it/350x150")
		div.overlay Overlay content
	h4.name Projektname
	div.description Kundenname
```
*/
.-events .list-event {
  margin: 0 0 2.5rem 0;
  position: relative;
}
.-events .list-event > a > .pic {
  width: 100%;
  height: 340px;
  background-repeat: no-repeat;
  background-position: center center;
}
.-events .list-event > a > .name {
  font-weight: bold;
  margin: 0.3125rem 0;
  height: 2.5rem;
}
.-events .list-event > a > .description {
  font-size: 1.0625rem;
  line-height: 1.5625rem;
  font-family: "Akkurat-Light";
  letter-spacing: 0.01rem;
  margin-top: 0.625rem;
}
.-events .list-event > a .overlay {
  color: white;
  padding: 20px;
  font-size: 1.5625rem;
  line-height: 2.0625rem;
  font-family: "Akkurat-Bold";
}
.-events .list-event > a .overlay > .more {
  float: right;
  position: absolute;
  bottom: 15px;
  right: 35px;
  font-size: 0.9375rem;
}
.-events .list-event > a .overlay > .more > .icon-arrow-long {
  position: relative;
  top: 4px;
  left: 5px;
}
.-events .list-event > a:hover > .pic {
  transition: 0.8s;
  filter: grayscale(80%);
}
.-events .-event h1.title {
  margin-top: 5.625rem;
}
.-events .-event p.teaser {
  display: inline;
}
.-events .-event .form-elements {
  margin-top: 2.8125rem;
  padding-left: 4.875rem;
}
.-events .-event .form-elements h3 {
  text-transform: uppercase;
  letter-spacing: 2px;
}
@media only screen and (max-width: 600px) {
  /* For mobile phones: */
  .list-project > a > .name {
    font-size: 1.25rem;
    margin: 0.3125rem 0;
  }
  .list-project > a > .description {
    font-size: 0.875rem;
  }
  .-events .-event .content-element p,
  .-events .-event .content-element h3 {
    padding-left: 0rem;
  }
  .-events .-event .form-elements {
    margin-top: 2.8125rem;
    padding-left: 0rem;
  }
}
/*
---
name: .list-project
category: Lists/Project
tag:
 - components
compile: true
---

```jade
div.list-project
	div.pic.pic-overlay
		img.responsive(src="http://placehold.it/350x150")
		div.overlay Overlay content
	h4.name Projektname
	div.description Kundenname
```
*/
.list-project {
  margin: 0 0 2.1875rem 0;
  position: relative;
}
.list-project > a > .pic {
  width: 100%;
  height: 340px;
  background-repeat: no-repeat;
  background-position: center center;
}
.list-project > a > .name {
  font-weight: bold;
  margin: 0.3125rem 0;
}
.list-project > a > .description {
  font-size: 0.9375rem;
  line-height: 1.5625rem;
  font-family: "Akkurat-Light";
  letter-spacing: 0.01rem;
}
.list-project > a .overlay {
  color: white;
  padding: 20px;
  font-size: 1.5625rem;
  line-height: 2.0625rem;
  font-family: "Akkurat-Bold";
}
.list-project > a .overlay > .more {
  float: right;
  position: absolute;
  bottom: 15px;
  right: 35px;
  font-size: 0.9375rem;
}
.list-project > a .overlay > .more > .icon-arrow-long {
  position: relative;
  top: 4px;
  left: 5px;
}
.list-project > a:hover > .pic {
  transition: 0.8s;
  filter: grayscale(80%);
}
@media only screen and (max-width: 600px) {
  /* For mobile phones: */
  .list-project > a > .name {
    font-size: 1.25rem;
    margin: 0.3125rem 0;
  }
  .list-project > a > .description {
    font-size: 0.875rem;
  }
}
/*
---
name: .list-service
category: Lists/Team
tag:
 - components
compile: true
---

```jade
div.list-service
	img.pic.responsive(src="http://placehold.it/350x150")
	h4.name Mein Name
	div.function Meine Funktion bei Lernetz
```
*/
.list-service {
  margin-bottom: 4.375rem;
}
.list-service > .name {
  font-size: 1.5625rem;
  font-family: "Akkurat-Bold";
  margin: 1.5625rem 0 1.0625rem;
}
.list-service > .description {
  font-size: 1.0625rem;
  font-family: "Akkurat-Light";
  padding-right: 6.25rem;
  letter-spacing: 0.011875rem;
  line-height: rem 26px;
}
.list-service > .formats-info-box {
  height: 100%;
  margin-bottom: 0;
  transition: background-color 0.5s ease;
  position: relative;
  width: 18.125rem;
  height: 18.125rem;
}
@media only screen and (max-width: 600px) {
  .list-service {
    text-align: center;
  }
  .list-service > .description {
    padding-right: 0rem;
  }
}
@media screen and (orientation: landscape) and (max-device-width: 780px) {
  .grid .list-service.medium-1-2 {
    width: 100%;
  }
}
.icon-strategie:hover .nadel {
  animation: rotate360 2s linear infinite;
  transform-origin: 50%;
}
@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*
---
name: .list-team
category: Lists/Team
tag:
 - components
compile: true
---

```jade
div.list-team
	img.pic.responsive(src="http://placehold.it/350x150")
	h4.name Mein Name
	div.function Meine Funktion bei Lernetz
```
*/
.list-team {
  margin: 0 0 2.1875rem 0;
  position: relative;
}
.list-team .member-picture {
  z-index: 2;
}
.list-team .member-video {
  z-index: 1;
}
.list-team > a > .name {
  font-size: 1.5625rem;
  margin: 0.3125rem 0;
}
.list-team > a > .function {
  font-size: 0.9375rem;
  line-height: 1.5625rem;
  font-family: "Akkurat-Light";
  letter-spacing: 0.01rem;
}
.list-team > a > img {
  display: block;
}
@media only screen and (max-width: 600px) {
  /* For mobile phones: */
  .list-team > a > .name {
    font-size: 1.25rem;
    margin: 0.3125rem 0;
  }
  .list-team > a > .function {
    font-size: 0.875rem;
  }
}
/*
---
name: .list-trainer
category: Lists/Trainer
tag:
 - components
compile: true
---

```jade
div.list-trainer
	img.pic.responsive(src="http://placehold.it/350x150")
	h4.name Mein Name
	div.function Meine Funktion bei Lernetz
```
*/
.-team h2.trainer-title {
  margin-top: 10.9375rem;
}
.-team p.lead {
  margin: 0 0 4.375rem 0;
  padding: 0;
}
.-team .list-trainer {
  display: grid;
  margin: 1.375rem 0;
  grid-template-columns: 9.375rem auto;
}
.-team .list-trainer > .trainer-pic > .pic {
  width: 8.125rem;
  border-radius: 100%;
}
.-team .list-trainer > .trainer-text {
  vertical-align: bottom;
}
.-team .list-trainer > .trainer-text span {
  display: block;
  margin-top: 50px;
}
.-team .list-trainer > .trainer-text > span,
.-team .list-trainer > .trainer-text > p {
  font-weight: normal;
  font-size: 1.0625rem;
  font-family: "Akkurat-Light";
}
@media only screen and (max-width: 1024px) {
  .-team .list-trainer {
    display: block;
  }
  .-team .list-trainer > .trainer-pic {
    text-align: center;
  }
  .-team .list-trainer > .trainer-text {
    text-align: center;
  }
  .-team .list-trainer > .trainer-text span {
    margin-top: 1.25rem;
    font-family: "Akkurat-Regular";
  }
}
@media only screen and (max-width: 600px) {
  .-team .list-trainer {
    display: block;
  }
  .-team .list-trainer > .trainer-pic {
    text-align: center;
  }
  .-team .list-trainer > .trainer-pic > .pic {
    width: 50%;
  }
  .-team .list-trainer > .trainer-text {
    text-align: center;
  }
  .-team .list-trainer > .trainer-text span {
    font-family: "Akkurat-Regular";
  }
}
.media-responsive.-media-1-1 {
  padding-bottom: 100%;
}
.navigation-main {
  font-size: 0.875rem;
  padding: 80px 50px;
}
.navigation-main > ul {
  list-style: none;
}
.navigation-main > ul > li {
  font-size: 1.5625rem;
  margin-bottom: 0.9375rem;
}
.navigation-main > ul > li > div > a {
  text-transform: uppercase;
  font-family: "Akkurat-Bold";
}
.navigation-main > ul > li > div > a:hover {
  border-bottom: 3px solid;
}
.navigation-main > ul > li > div > .icon-external .icon {
  display: inline-block;
  height: 12px;
  width: 12px;
  background-repeat: no-repeat;
  background-color: currentColor;
  mask-image: url(../images/link-external.svg);
  margin: 0 0 3px 10px;
}
.navigation-main .navigation-coffee {
  margin: 50px 0;
}
.navigation-main .navigation-coffee .coffee-text {
  width: 70%;
  float: left;
}
.navigation-main .navigation-coffee .coffee-text > h3 {
  font-family: "Akkurat-Bold";
  font-size: 1.0625rem;
  line-height: 1.5625rem;
}
.navigation-main .navigation-coffee .coffee-text > p {
  float: left;
}
.navigation-main .navigation-adresse {
  clear: both;
  margin-top: 150px;
}
.navigation-main .navigation-adresse > strong {
  display: block;
  margin-bottom: 10px;
}
.navigation-main .navigation-adresse .left {
  width: 45%;
  float: left;
}
.navigation-main .navigation-adresse .left.divider {
  border-right: 2px solid;
  padding-right: 10px;
}
.navigation-main .navigation-adresse .right {
  padding: 0 0 10px 10px;
  float: left;
  width: auto;
}
.navigation-main .button-newsletter {
  margin-top: 1.25rem;
  clear: both;
}
.navigation-main .button-newsletter button {
  position: relative;
  background: transparent;
  border-radius: 1.5625rem;
  border: 2px solid;
  text-transform: uppercase;
  line-height: 1.625rem;
  padding: 4px 20px 2px 35px;
  cursor: pointer;
}
.navigation-main .button-newsletter button .icon {
  position: absolute;
  left: 12px;
  top: 6px;
  height: 16px;
  width: 14px;
  background-color: currentColor;
  mask-image: url(../images/pen.svg);
}
.navigation-main .mail-phone {
  padding: 10px 0;
  clear: both;
}
.navigation-main .mail-phone strong {
  display: inherit;
}
.navigation-main .external-links {
  margin: 30px 0 0 0;
  clear: both;
}
.navigation-main .external-links > ul > li {
  display: block;
  position: relative;
  width: 100%;
  margin: 0;
  border-bottom: 2px solid;
  line-height: 3.125rem;
}
.navigation-main .external-links > ul > li:hover {
  color: inherit;
}
.navigation-main .external-links > ul > li:hover:before {
  color: inherit;
}
.navigation-main .external-links > ul > li:before {
  position: absolute;
  right: 0;
  top: 10px;
  font-size: 30px;
}
.navigation-main .external-links > ul > li:first-child {
  border-top: 2px solid;
}
.navigation-main .external-links > ul > li > a {
  font-family: "Akkurat-Bold";
  text-transform: uppercase;
  display: block;
}
.navigation-main .external-links > ul > li > a:hover {
  border-bottom: none;
  color: inherit;
}
@media only screen and (max-width: 600px) {
  .navigation-main {
    /* For mobile phones: */
    font-size: 0.875rem;
    padding: 80px 20px;
  }
  .navigation-main > ul {
    list-style: none;
  }
  .navigation-main > ul > li {
    font-size: 1.25rem;
    margin-bottom: 0.9375rem;
  }
  .navigation-main > ul > li > a {
    text-transform: uppercase;
    font-family: "Akkurat-Bold";
  }
  .navigation-main > ul > li > a:hover {
    border-bottom: 3px solid;
  }
  .navigation-main .navigation-adresse {
    margin-top: 50px;
    font-size: 0.6875rem;
  }
  .navigation-main .navigation-adresse .left {
    width: 100%;
    padding: 0 0 5px 0;
  }
  .navigation-main .navigation-adresse .left.divider {
    border-right: none;
  }
  .navigation-main .navigation-adresse .right {
    padding: 5px 0 10px 0px;
    width: 100%;
  }
  .navigation-main .navigation-adresse .mail-phone {
    padding: 30px 0;
  }
  .navigation-main .navigation-adresse .mail-phone .left,
  .navigation-main .navigation-adresse .mail-phone .right {
    padding: 0;
  }
  .navigation-main .external-links > ul > li {
    font-size: rem 17px;
  }
}
.offer-icon {
  width: 8.125rem;
  margin-left: 2.1875rem;
}
.offer-icon.-narrow {
  width: 6.25rem;
  margin-left: 3.125rem;
}
.offer-icon.-wide {
  width: 11.25rem;
  margin-left: 0.625rem;
}
@media only screen and (max-width: 600px) {
  /* For mobile phones: */
  .offer-icon {
    width: 8.125rem;
    margin-left: 0;
  }
  .offer-icon.-narrow {
    width: 6.25rem;
    margin-left: 0;
  }
  .offer-icon.-wide {
    width: 11.25rem;
    margin-left: 0;
  }
}
/*
---
name: .page-contact
category: Layouts/Page Contact
tag:
 - layouts
compile: true
---

```jade
.page-contact.row
  .logo Logo
  .page Pagename
  .menu Menu
```

*/
.contact-header {
  font-family: "Akkurat-Bold";
  font-size: 1.875rem;
  text-transform: uppercase;
  line-height: 2.375rem;
  margin-bottom: 35px;
}
.list-maps {
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 35px;
}
.list-maps img {
  width: 100%;
  height: auto;
}
.list-maps:hover .overlay {
  visibility: visible;
}
.list-maps .overlay {
  z-index: 5;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 1.5625rem;
  visibility: hidden;
}
.list-maps .overlay .location-pin {
  margin: 25px 0 0 25px;
}
.list-maps .overlay .location {
  padding: 25px 0 0 50px;
}
.list-maps iframe {
  width: 100%;
  height: 100%;
}
.-contact > .page-teaser > .content > .small {
  padding-top: 3.125rem;
  font-size: 1.875rem;
}
.-contact > .page-teaser > .content > .small span {
  padding-right: 1.875rem;
  vertical-align: middle;
}
.-contact > .page-teaser > .content > .small span a {
  font-family: "Akkurat-Regular";
}
.-contact > .page-teaser > .content > .small .icon-phone:before,
.-contact > .page-teaser > .content > .small .icon-mail:before {
  position: relative;
  top: 0.25rem;
}
@media only screen and (max-width: 1024px) {
  /* For mobile phones: */
  .list-maps .overlay {
    visibility: visible;
  }
}
@media only screen and (max-width: 600px) {
  /* For mobile phones: */
  .-contact > .page-teaser > .content > .small {
    font-size: 1.4375rem;
    width: 100%;
    padding-right: 0px;
  }
  .-contact > .page-teaser > .content > .small .icon-phone {
    display: block;
    margin-bottom: 15px;
  }
  .list-maps .overlay {
    font-size: 1.25rem;
    visibility: visible;
  }
}
.-event .item-navigation a {
  color: #003160;
}
.-event .teaser {
  font-size: 2.8125rem;
  font-family: "Akkurat-Regular";
  line-height: 3.625rem;
  letter-spacing: 0.025rem;
  margin: 6.125rem 0 2.1875rem 0;
  font-weight: normal;
}
.-event h3 {
  margin-bottom: 1.25rem;
}
.-event .content-element p,
.-event .content-element h1,
.-event .content-element h2,
.-event .content-element h3,
.-event .content-element h4,
.-event .content-element ul,
.-event .content-element ol {
  padding-left: 4.875rem;
}
.-event .content-element p.last,
.-event .content-element h1.last,
.-event .content-element h2.last,
.-event .content-element h3.last,
.-event .content-element h4.last,
.-event .content-element ul.last,
.-event .content-element ol.last {
  padding-left: 0;
}
.-event .content-element .quote-element p {
  padding-left: 0;
}
.-event .item-navigation.-outro {
  text-align: left;
  line-height: 1.625rem;
  font-size: 0.9375rem;
}
.-event .item-navigation.-outro > a.next {
  position: relative;
  top: -2px;
  left: 2px;
}
@media screen and (max-width: 600px) {
  .-event .content-element p,
  .-event .content-element h1,
  .-event .content-element h2,
  .-event .content-element h3,
  .-event .content-element h4,
  .-event .content-element ul,
  .-event .content-element ol,
  .-event .content-element .quote-element {
    padding-left: 0;
  }
  .-event .teaser {
    font-size: 2.5625rem;
    line-height: 3.375rem;
    letter-spacing: 0.028125rem;
    margin-top: 4.375rem;
  }
}
@media screen and (max-width: 400px) {
  .-event .teaser {
    font-size: 1.875rem;
    line-height: 2.5rem;
    letter-spacing: 0.020625rem;
    margin-top: 2.1875rem;
    margin-bottom: 1.25rem;
  }
}
/*
---
name: .page-header
category: Layouts/Page Header
tag:
 - layouts
compile: true
---

```jade
.page-header.row
  .logo Logo
  .page Pagename
  .menu Menu
```

*/
.page-header {
  margin: 0 auto -50px auto;
  padding: 12px 0;
  color: #003160;
  background-color: #fff;
  transition: background-color 0.5s;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.3);
}
.page-header .page {
  width: 100%;
  padding-left: 0.75rem;
  font-size: 0.9375rem;
}
.page-header .menu {
  font-size: 27px;
  transition: font-size 0.5s;
  cursor: pointer;
  display: inline-block;
  height: 27px;
  vertical-align: center;
}
.page-header .logo {
  transition: height 0.5s;
  height: 21px;
  width: auto;
}
.page-header .icon-logo-ln:before {
  font-size: 9.375rem;
  line-height: 0;
}
.page-header.-colored {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
}
.page-header.-colored .logo {
  height: 30px;
}
.page-header.-colored .menu {
  font-size: 34px;
}
@media only screen and (max-width: 600px) {
  .page-header {
    /* For mobile phones: */
  }
  .page-header .page {
    visibility: hidden;
  }
}
/*
---
name: .page-main
category: Page Layouts/Main
tag:
 - layouts
compile: true
---

# Closed state
```jade
div.page-main
	div.content Some content
	div.navigation Some navi content
```

# Open state

*/
.page-main {
  position: relative;
}
.page-main.-open > .navigation {
  transform: translateX(0);
}
.page-main.-open > .darkener {
  transform: translateX(0);
  background-color: rgba(0, 0, 0, 0.3);
}
.page-main.-start .page {
  visibility: hidden;
}
.page-main > .navigation {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 500px;
  max-width: 100%;
  z-index: 15;
  transform: translateX(100%);
  transition: transform 0.3s;
  overflow: auto;
}
.page-main > .header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.page-main > .darkener {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0);
  z-index: 10;
  transform: translateX(-100%);
  transition: background-color 1s;
}
.page-main > .coffee {
  position: fixed;
  bottom: 1.25rem;
  left: calc(50% + 512px - 45px);
  z-index: 3;
  text-align: right;
}
.page-main > .content {
  padding-top: 7.8125rem;
  padding-bottom: 10.9375rem;
  min-height: 100vh;
}
.page-main > .content > .bottom-175 {
  margin-bottom: 10.9375rem;
}
.page-main.-start .page-teaser,
.page-main.-start .page-header.-colored {
  background-color: #011a33;
  color: #f3f3f2;
}
.page-main.-start .page-teaser a,
.page-main.-start .page-header.-colored a {
  color: #f3f3f2;
}
.page-main.-start .page-teaser a:hover,
.page-main.-start .page-header.-colored a:hover {
  color: #003160;
}
.page-main.-start .page-teaser .logo path,
.page-main.-start .page-header.-colored .logo path {
  fill: #f3f3f2;
}
.page-main.-start .page-teaser a.-contact:hover,
.page-main.-start .page-header.-colored a.-contact:hover {
  background-color: #ec6449;
  color: #e3e3e5;
}
.page-main.-start .page-teaser a.-contact:hover a,
.page-main.-start .page-header.-colored a.-contact:hover a {
  color: #e3e3e5;
}
.page-main.-start .page-teaser a.-contact:hover a:hover,
.page-main.-start .page-header.-colored a.-contact:hover a:hover {
  color: #e3e3e5;
}
.page-main.-start .page-teaser a.-contact:hover .logo path,
.page-main.-start .page-header.-colored a.-contact:hover .logo path {
  fill: #e3e3e5;
}
.page-main.-start .page-teaser a.-team:hover,
.page-main.-start .page-header.-colored a.-team:hover {
  background-color: #e3e3e5;
  color: #ec6449;
}
.page-main.-start .page-teaser a.-team:hover a,
.page-main.-start .page-header.-colored a.-team:hover a {
  color: #ec6449;
}
.page-main.-start .page-teaser a.-team:hover a:hover,
.page-main.-start .page-header.-colored a.-team:hover a:hover {
  color: #ec6449;
}
.page-main.-start .page-teaser a.-team:hover .logo path,
.page-main.-start .page-header.-colored a.-team:hover .logo path {
  fill: #ec6449;
}
.page-main.-start .page-teaser a.-blog:hover,
.page-main.-start .page-header.-colored a.-blog:hover {
  background-color: #3f3f40;
  color: #f2f2f2;
}
.page-main.-start .page-teaser a.-blog:hover a,
.page-main.-start .page-header.-colored a.-blog:hover a {
  color: #f2f2f2;
}
.page-main.-start .page-teaser a.-blog:hover a:hover,
.page-main.-start .page-header.-colored a.-blog:hover a:hover {
  color: #f2f2f2;
}
.page-main.-start .page-teaser a.-blog:hover .logo path,
.page-main.-start .page-header.-colored a.-blog:hover .logo path {
  fill: #f2f2f2;
}
.page-main.-start .page-teaser a.-project:hover,
.page-main.-start .page-header.-colored a.-project:hover {
  background-color: #d9d9da;
  color: #003160;
}
.page-main.-start .page-teaser a.-project:hover a,
.page-main.-start .page-header.-colored a.-project:hover a {
  color: #003160;
}
.page-main.-start .page-teaser a.-project:hover a:hover,
.page-main.-start .page-header.-colored a.-project:hover a:hover {
  color: #003160;
}
.page-main.-start .page-teaser a.-project:hover .logo path,
.page-main.-start .page-header.-colored a.-project:hover .logo path {
  fill: #003160;
}
.page-main.-start .page-teaser a.-offer:hover,
.page-main.-start .page-header.-colored a.-offer:hover {
  background-color: #fff;
  color: #003160;
}
.page-main.-start .page-teaser a.-offer:hover a,
.page-main.-start .page-header.-colored a.-offer:hover a {
  color: #003160;
}
.page-main.-start .page-teaser a.-offer:hover a:hover,
.page-main.-start .page-header.-colored a.-offer:hover a:hover {
  color: #003160;
}
.page-main.-start .page-teaser a.-offer:hover .logo path,
.page-main.-start .page-header.-colored a.-offer:hover .logo path {
  fill: #003160;
}
.page-main.-start .page-teaser a.-events:hover,
.page-main.-start .page-header.-colored a.-events:hover {
  background-color: #fff;
  color: #003160;
}
.page-main.-start .page-teaser a.-events:hover a,
.page-main.-start .page-header.-colored a.-events:hover a {
  color: #003160;
}
.page-main.-start .page-teaser a.-events:hover a:hover,
.page-main.-start .page-header.-colored a.-events:hover a:hover {
  color: #003160;
}
.page-main.-start .page-teaser a.-events:hover .logo path,
.page-main.-start .page-header.-colored a.-events:hover .logo path {
  fill: #003160;
}
.page-main.-start .navigation {
  background-color: #FFF;
  color: #003160;
}
.page-main.-start .navigation a {
  color: #003160;
}
.page-main.-start .navigation a:hover {
  color: #000000;
}
.page-main.-start .navigation .logo path {
  fill: #003160;
}
.page-main.-start .page-teaser .arrow {
  display: none;
}
.page-main.-offer .page-teaser,
.page-main.-offer .page-header.-colored,
.page-main.-offer .navigation {
  background-color: #011A33;
  color: #f3f3f2;
}
.page-main.-offer .page-teaser a,
.page-main.-offer .page-header.-colored a,
.page-main.-offer .navigation a {
  color: #f3f3f2;
}
.page-main.-offer .page-teaser a:hover,
.page-main.-offer .page-header.-colored a:hover,
.page-main.-offer .navigation a:hover {
  color: #f3f3f2;
}
.page-main.-offer .page-teaser .logo path,
.page-main.-offer .page-header.-colored .logo path,
.page-main.-offer .navigation .logo path {
  fill: #f3f3f2;
}
.page-main.-offer .navigation {
  background-color: #011A33;
  color: #f2f2f2;
}
.page-main.-offer .navigation a {
  color: #f2f2f2;
}
.page-main.-offer .navigation a:hover {
  color: #bfbfbf;
}
.page-main.-offer .navigation .logo path {
  fill: #f2f2f2;
}
.page-main.-offer .page-header {
  background-color: white;
  color: #003160;
}
.page-main.-offer .page-header a {
  color: #003160;
}
.page-main.-offer .page-header a:hover {
  color: #000;
}
.page-main.-offer .page-header .logo path {
  fill: #003160;
}
.page-main.-team .page-teaser,
.page-main.-team .navigation,
.page-main.-team .page-header.-colored {
  background-color: #fafafa;
  color: #ec6449;
}
.page-main.-team .page-teaser a,
.page-main.-team .navigation a,
.page-main.-team .page-header.-colored a {
  color: #ec6449;
}
.page-main.-team .page-teaser a:hover,
.page-main.-team .navigation a:hover,
.page-main.-team .page-header.-colored a:hover {
  color: #d33516;
}
.page-main.-team .page-teaser .logo path,
.page-main.-team .navigation .logo path,
.page-main.-team .page-header.-colored .logo path {
  fill: #ec6449;
}
.page-main.-team .page-header {
  background-color: white;
  color: #ec6449;
}
.page-main.-team .page-header a {
  color: #ec6449;
}
.page-main.-team .page-header a:hover {
  color: #d33516;
}
.page-main.-team .page-header .logo path {
  fill: #ec6449;
}
.page-main.-blog .page-teaser,
.page-main.-blog .page-header,
.page-main.-blog .navigation {
  background-color: #3f3f40;
  color: #f3f3f2;
}
.page-main.-blog .page-teaser a,
.page-main.-blog .page-header a,
.page-main.-blog .navigation a {
  color: #f3f3f2;
}
.page-main.-blog .page-teaser a:hover,
.page-main.-blog .page-header a:hover,
.page-main.-blog .navigation a:hover {
  color: #f3f3f2;
}
.page-main.-blog .page-teaser .logo path,
.page-main.-blog .page-header .logo path,
.page-main.-blog .navigation .logo path {
  fill: #f3f3f2;
}
.page-main.-blog .page-header.-colored {
  background-color: #3f3f40;
  color: #f3f3f2;
}
.page-main.-blog .page-header.-colored a {
  color: #f3f3f2;
}
.page-main.-blog .page-header.-colored a:hover {
  color: #f3f3f2;
}
.page-main.-blog .page-header.-colored .logo path {
  fill: #f3f3f2;
}
.page-main.-contact .navigation,
.page-main.-contact .page-teaser,
.page-main.-contact .page-header.-colored {
  background-color: #ec6449;
  color: #e3e3e5;
}
.page-main.-contact .navigation a,
.page-main.-contact .page-teaser a,
.page-main.-contact .page-header.-colored a {
  color: #e3e3e5;
}
.page-main.-contact .navigation a:hover,
.page-main.-contact .page-teaser a:hover,
.page-main.-contact .page-header.-colored a:hover {
  color: #aeaeb4;
}
.page-main.-contact .navigation .logo path,
.page-main.-contact .page-teaser .logo path,
.page-main.-contact .page-header.-colored .logo path {
  fill: #e3e3e5;
}
.page-main.-contact .page-header {
  background-color: white;
  color: #ec6449;
}
.page-main.-contact .page-header a {
  color: #ec6449;
}
.page-main.-contact .page-header a:hover {
  color: #bb2f14;
}
.page-main.-contact .page-header .logo path {
  fill: #ec6449;
}
.page-main.-projects .page-teaser,
.page-main.-projects .page-header.-colored,
.page-main.-projects .navigation {
  background-color: #f3f3f2;
  color: #003160;
}
.page-main.-projects .page-teaser a,
.page-main.-projects .page-header.-colored a,
.page-main.-projects .navigation a {
  color: #003160;
}
.page-main.-projects .page-teaser a:hover,
.page-main.-projects .page-header.-colored a:hover,
.page-main.-projects .navigation a:hover {
  color: #003160;
}
.page-main.-projects .page-teaser .logo path,
.page-main.-projects .page-header.-colored .logo path,
.page-main.-projects .navigation .logo path {
  fill: #003160;
}
.page-main.-projects .page-header {
  background-color: white;
  color: #003160;
}
.page-main.-projects .page-header a {
  color: #003160;
}
.page-main.-projects .page-header a:hover {
  color: #000;
}
.page-main.-projects .page-header .logo path {
  fill: #003160;
}
.page-main.-events .page-teaser,
.page-main.-events .page-header.-colored,
.page-main.-events .navigation {
  background-color: #00aecb;
  color: #f3f3f2;
}
.page-main.-events .page-teaser a,
.page-main.-events .page-header.-colored a,
.page-main.-events .navigation a {
  color: #f3f3f2;
}
.page-main.-events .page-teaser a:hover,
.page-main.-events .page-header.-colored a:hover,
.page-main.-events .navigation a:hover {
  color: #f3f3f2;
}
.page-main.-events .page-teaser .logo path,
.page-main.-events .page-header.-colored .logo path,
.page-main.-events .navigation .logo path {
  fill: #f3f3f2;
}
.page-main.-events .page-header {
  background-color: white;
  color: #00aecb;
}
.page-main.-events .page-header a {
  color: #00aecb;
}
.page-main.-events .page-header a:hover {
  color: #000;
}
.page-main.-events .page-header .logo path {
  fill: #00aecb;
}
.page-main.-newsletter .page-teaser,
.page-main.-newsletter .page-header.-colored,
.page-main.-newsletter .navigation {
  background-color: #011A33;
  color: white;
}
.page-main.-newsletter .page-teaser a,
.page-main.-newsletter .page-header.-colored a,
.page-main.-newsletter .navigation a {
  color: white;
}
.page-main.-newsletter .page-teaser a:hover,
.page-main.-newsletter .page-header.-colored a:hover,
.page-main.-newsletter .navigation a:hover {
  color: #d9d9d9;
}
.page-main.-newsletter .page-teaser .logo path,
.page-main.-newsletter .page-header.-colored .logo path,
.page-main.-newsletter .navigation .logo path {
  fill: white;
}
.page-main.-newsletter .page-header {
  background-color: white;
  color: #011A33;
}
.page-main.-newsletter .page-header a {
  color: #011A33;
}
.page-main.-newsletter .page-header a:hover {
  color: #000;
}
.page-main.-newsletter .page-header .logo path {
  fill: #011A33;
}
.page-main.-container .page-teaser,
.page-main.-container .page-header.-colored,
.page-main.-container .navigation {
  background-color: #011A33;
  color: white;
}
.page-main.-container .page-teaser a,
.page-main.-container .page-header.-colored a,
.page-main.-container .navigation a {
  color: white;
}
.page-main.-container .page-teaser a:hover,
.page-main.-container .page-header.-colored a:hover,
.page-main.-container .navigation a:hover {
  color: #d9d9d9;
}
.page-main.-container .page-teaser .logo path,
.page-main.-container .page-header.-colored .logo path,
.page-main.-container .navigation .logo path {
  fill: white;
}
.page-main.-container .page-header {
  background-color: white;
  color: #011A33;
}
.page-main.-container .page-header a {
  color: #011A33;
}
.page-main.-container .page-header a:hover {
  color: #000;
}
.page-main.-container .page-header .logo path {
  fill: #011A33;
}
.page-main.-project .page-header.-colored,
.page-main.-project .navigation {
  background-color: #f3f3f2;
  color: #003160;
}
.page-main.-project .page-header.-colored a,
.page-main.-project .navigation a {
  color: #003160;
}
.page-main.-project .page-header.-colored a:hover,
.page-main.-project .navigation a:hover {
  color: #003160;
}
.page-main.-project .page-header.-colored .logo path,
.page-main.-project .navigation .logo path {
  fill: #003160;
}
.page-main.-project .page-header {
  background-color: white;
  color: #003160;
}
.page-main.-project .page-header a {
  color: #003160;
}
.page-main.-project .page-header a:hover {
  color: #000;
}
.page-main.-project .page-header .logo path {
  fill: #003160;
}
.page-main.-project .page-teaser {
  background-color: #e3e3e5;
  color: #003160;
}
.page-main.-project .page-teaser a {
  color: #003160;
}
.page-main.-project .page-teaser a:hover {
  color: #000;
}
.page-main.-project .page-teaser .logo path {
  fill: #003160;
}
@media only screen and (max-width: 600px) {
  /* For mobile phones: */
  .page-main .page-fluid {
    padding: 0 1.25rem;
  }
  .page-main > .coffee {
    right: 20px;
  }
  .page-main > .content {
    padding-top: 4.6875rem;
    padding-bottom: 0.3125rem;
    min-height: 100vh;
  }
  .page-main > .content > .bottom-175 {
    margin-bottom: 4.6875rem;
  }
}
@media screen and (orientation: landscape) and (max-device-width: 780px) {
  .page-main .page-fluid {
    padding: 0 1.25rem;
  }
  .page-main > .coffee {
    right: 20px;
  }
  .page-main > .content {
    padding-top: 4.6875rem;
    padding-bottom: 0.3125rem;
    min-height: 100vh;
  }
  .page-main > .content > .bottom-175 {
    margin-bottom: 4.6875rem;
  }
}
/*
---
name: .page-member
category: Page Layouts/Teaser
tag:
 - components
compile: true
---

```jade
.page-member
    div.member-image Some content
    div.member-detail Some content
```
*/
.-member > .item-navigation {
  width: 100%;
  text-align: right;
  margin-bottom: 15px;
}
.-member > .member-container > .member-details h2 {
  font-family: "Akkurat-Regular";
  font-weight: 100;
  font-size: 1.875rem;
}
.-member > .member-container > .member-details p {
  font-size: rem 17px;
  font-family: "Akkurat-Light";
  line-height: 1.625rem;
  letter-spacing: 0.19px;
}
.-member > .member-container > .member-details .social-links {
  margin-bottom: 1.625rem;
}
.-member > .member-container > .member-details .social-links > a {
  color: #ec6449;
  margin-right: 1rem;
}
.-member > .member-container > .member-details .social-links > a:hover .link-icon {
  fill: #bb2f14 !important;
}
.-member > .member-container > .member-details > .member-mail,
.-member > .member-container > .member-details > .member-phone {
  margin: 10px 0 25px 0;
  font-size: 1.3125rem;
  color: #ec6449;
}
.-member > .member-container > .member-details > .member-mail > a,
.-member > .member-container > .member-details > .member-phone > a {
  color: #ec6449;
}
@media only screen and (max-width: 600px) {
  /* For mobile phones: */
  .-member > .member-container > .member-details {
    padding-bottom: 100px;
  }
  .-member > .member-container > .member-details h2 {
    font-family: "Akkurat-Regular";
    font-size: 1.875rem;
  }
  .-member > .member-container > .member-details p {
    font-size: rem 22px;
    font-family: "Akkurat-Light";
    line-height: 1.625rem;
    letter-spacing: 0.15px;
  }
  .-member > .member-container > .member-details > .member-mail,
  .-member > .member-container > .member-details .member-phone {
    font-size: 0.875rem;
  }
  .-member > .member-container .link-icon {
    width: 32px;
  }
  .-member > .member-container .link-icon.-vcard {
    width: 22px !important;
  }
  .-member > .member-container .link-icon.-birdie {
    width: 22px;
    margin: 0 !important;
  }
}
@media screen and (orientation: landscape) and (max-device-width: 780px) {
  .-member > .member-container > .member-details h2 {
    font-family: "Akkurat-Regular";
    font-size: 1.25rem;
  }
  .-member > .member-container > .member-details p {
    font-size: rem 22px;
    font-family: "Akkurat-Light";
    line-height: 1.625rem;
    letter-spacing: 0.15px;
  }
  .-member > .member-container > .member-details > .member-mail {
    font-size: 0.875rem;
  }
}
.-newsletter .item-navigation a {
  color: #003160;
}
.-newsletter .teaser {
  font-size: 2.8125rem;
  font-family: "Akkurat-Regular";
  line-height: 3.625rem;
  letter-spacing: 0.025rem;
  margin: 6.125rem 0 2.1875rem 0;
  font-weight: normal;
}
.-newsletter h3 {
  margin-bottom: 1.25rem;
}
.-newsletter .titel-element {
  width: inherit;
  float: left;
}
.-newsletter .titel-element h1 {
  display: inline-block;
  line-height: inherit;
  margin: 0;
  padding: 0;
}
.-newsletter .paragraph-element {
  display: inline-block;
}
.-newsletter .paragraph-element p {
  font-size: 2.5625rem;
  line-height: 3.375rem;
  display: inline-block;
}
.-newsletter .newsletter-form {
  margin-top: 30px;
}
.-newsletter .newsletter-form h3 {
  display: none;
}
.-newsletter .newsletter-form input {
  color: black;
  border: none;
}
.-newsletter .newsletter-form input[type=submit] {
  border: 2px solid white;
  font-size: 0.875rem;
  text-transform: uppercase;
  margin-top: 10px;
  color: white;
}
@media screen and (max-width: 600px) {
  .-project .content-element p,
  .-project .content-element h1,
  .-project .content-element h2,
  .-project .content-element h3,
  .-project .content-element h4,
  .-project .content-element ul,
  .-project .content-element ol,
  .-project .content-element .quote-element {
    padding-left: 0;
  }
  .-project .teaser {
    font-size: 2.5625rem;
    line-height: 3.375rem;
    letter-spacing: 0.028125rem;
    margin-top: 4.375rem;
  }
}
@media screen and (max-width: 400px) {
  .-project .teaser {
    font-size: 1.875rem;
    line-height: 2.5rem;
    letter-spacing: 0.020625rem;
    margin-top: 2.1875rem;
    margin-bottom: 1.25rem;
  }
}
.-offer .formats-info-box {
  margin: 0 0 2.1875rem 0;
  position: relative;
}
.-offer .formats-info-box > .pic {
  background-color: #F2F2F2;
  height: 340px;
  background-repeat: no-repeat;
  background-position: center center;
}
.-offer .formats-info-box > .pic .title-overlay {
  position: absolute;
  text-align: center;
  width: 100%;
}
.-offer .formats-info-box > .pic .title-overlay object {
  margin-top: 70px;
}
.-offer .formats-info-box > .pic .title-overlay .infobox-image {
  display: inline-block;
  margin-top: 70px;
}
.-offer .formats-info-box > .pic .title-overlay h4 {
  font-size: 1.5625rem;
  line-height: 2.0625rem;
  color: #003160;
  margin-top: 50px;
}
.-offer .formats-info-box > .pic > .formats-description {
  padding: 20px;
  color: white;
}
.-offer .formats-info-box > .pic > .formats-description h4 {
  margin-bottom: 10px;
}
.-offer .formats-info-box > .pic > .formats-description p {
  font-size: 1.0625rem;
  line-height: 1.375rem;
}
.-offer .formats-info-box .pic-overlay {
  position: relative;
  overflow: hidden;
}
.-offer .formats-info-box .pic-overlay img {
  display: block;
}
.-offer .formats-info-box .pic-overlay > .overlay {
  background-color: #011A33;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  transition: transform 0.3s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.-offer .formats-info-box .pic-overlay:hover > .overlay {
  transform: translateY(0);
}
.-offer .formats-info-box .pic-overlay:hover > .title-overlay {
  transform: translateY(0);
}
@media screen and (max-width: 600px) {
  .-offer .formats-info-box {
    margin-bottom: 2.8125rem;
  }
}
@media screen and (max-width: 400px) {
  .-offer .teaser {
    font-size: 1.875rem;
    line-height: 2.5rem;
    letter-spacing: 0.020625rem;
    margin-top: 2.1875rem;
    margin-bottom: 1.25rem;
  }
}
.-project .item-navigation a {
  color: #003160;
}
.-project a {
  color: #003160;
  display: inline-block;
  border-bottom: 1px solid #003160;
  line-height: normal;
}
.-project .navigation-main .external-links > ul > li a {
  line-height: 3.125rem;
}
.-project a.next,
.-project a.arrow,
.-project .item-navigation a,
.-project .header a,
.-project .navigation a {
  border: none;
}
.-project .navigation a {
  display: inline;
}
.-project .navigation a.hidden {
  display: none;
}
.-project .teaser {
  font-size: 2.8125rem;
  font-family: "Akkurat-Regular";
  line-height: 3.625rem;
  letter-spacing: 0.025rem;
  margin: 6.125rem 0 2.1875rem 0;
  font-weight: normal;
}
.-project .content-element p,
.-project .content-element h1,
.-project .content-element h2,
.-project .content-element h3,
.-project .content-element h4,
.-project .content-element ul,
.-project .content-element ol {
  padding-left: 4.875rem;
}
.-project .content-element p.last,
.-project .content-element h1.last,
.-project .content-element h2.last,
.-project .content-element h3.last,
.-project .content-element h4.last,
.-project .content-element ul.last,
.-project .content-element ol.last {
  padding-left: 0;
}
.-project .content-element .quote-element p {
  padding-left: 0;
}
.-project .item-navigation.-outro {
  text-align: left;
  line-height: 1.625rem;
  font-size: 0.9375rem;
}
.-project .item-navigation.-outro > a.next {
  position: relative;
  top: -2px;
  left: 2px;
}
@media screen and (max-width: 600px) {
  .-project .content-element p,
  .-project .content-element h1,
  .-project .content-element h2,
  .-project .content-element h3,
  .-project .content-element h4,
  .-project .content-element ul,
  .-project .content-element ol,
  .-project .content-element .quote-element {
    padding-left: 0;
  }
  .-project .teaser {
    font-size: 2.5625rem;
    line-height: 3.375rem;
    letter-spacing: 0.028125rem;
    margin-top: 4.375rem;
  }
}
@media screen and (max-width: 400px) {
  .-project .teaser {
    font-size: 1.875rem;
    line-height: 2.5rem;
    letter-spacing: 0.020625rem;
    margin-top: 2.1875rem;
    margin-bottom: 1.25rem;
  }
}
.-projects .projects-container {
  margin-bottom: 8.4375rem;
}
@media screen and (max-width: 600px) {
  .-projects .projects-container {
    margin-bottom: 2.8125rem;
  }
}
@media screen and (max-width: 400px) {
  .-projects .teaser {
    font-size: 1.875rem;
    line-height: 2.5rem;
    letter-spacing: 0.020625rem;
    margin-top: 2.1875rem;
    margin-bottom: 1.25rem;
  }
}
/*
---
name: .page-teaser
category: Page Layouts/Teaser
tag:
 - components
compile: true
---

```jade
.page-teaser
    div.content Some content
    div.arrow Arrow
    div.socialmedia Socialmedia
```
*/
.page-teaser {
  position: relative;
  height: 100vh;
  font-size: 2.8125rem;
}
.page-teaser h1 {
  line-height: normal;
  font-weight: normal;
  font-family: "Akkurat-Regular";
}
.page-teaser .mobile {
  display: none;
}
.page-teaser > .content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.page-teaser > .content a {
  font-family: "Akkurat-Bold";
}
.page-teaser > .content a:hover {
  font-family: "Akkurat-Bold";
}
.page-teaser > .arrow {
  font-size: 1.5625rem;
  display: block;
  position: absolute;
  margin: 0 50%;
  bottom: 3.75rem;
  text-align: center;
  z-index: 5;
  animation-name: arrow-up-down;
  animation-duration: 1s;
  animation-iteration-count: 3;
  animation-timing-function: ease-in-out;
}
.page-teaser > .arrow:before {
  font-size: 2.875rem;
}
.page-teaser > .socialmedia {
  position: absolute;
  bottom: 1.25rem;
  left: 0;
  width: 100%;
  z-index: 5;
}
@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .page-teaser {
    font-size: 3.4375rem;
  }
  .page-teaser .mobile {
    display: none;
  }
  .page-teaser .desktop {
    display: block;
  }
}
@media only screen and (max-width: 600px) {
  /* For mobile phones: */
  .page-teaser {
    font-size: 1.875rem;
  }
  .page-teaser .mobile {
    display: inherit;
  }
  .page-teaser .desktop {
    display: none;
  }
  .page-teaser .arrow {
    font-size: 1.25rem;
    bottom: 1.875rem;
    animation-name: arrow-up-down-small;
  }
}
/*
---
name: .pic-overlay
category: Components/Overlay
tag:
 - components
compile: true
---

```jade
div.pic-overlay
    Some content
    div.overlay Overlay
```
*/
.pic-overlay {
  position: relative;
  overflow: hidden;
}
.pic-overlay img {
  display: block;
}
.pic-overlay > .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  transition: transform 0.3s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pic-overlay:hover > .overlay {
  transform: translateY(0);
}
.page-main.-start {
  overflow-x: hidden;
}
@keyframes spaceship {
  0% {
    top: 15%;
  }
  50% {
    top: 10%;
  }
  100% {
    top: 15%;
  }
}
@keyframes framesize {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.page-main.-start .spaceship {
  position: absolute;
  width: 200px;
  right: -200px;
  top: 20%;
  z-index: 50;
  cursor: pointer;
  transition: all 10s;
  animation-name: spaceship;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}
.page-main.-start .spaceship img {
  width: 100%;
}
.page-main.-start:hover .spaceship {
  right: 50%;
}
.page-main.-start .value-animation {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  overflow: hidden;
}
.page-main.-start .value-animation.-open {
  display: block;
}
.page-main.-start .value-animation .frame {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  border: none;
  animation-name: framesize;
  animation-duration: 0.5s;
}
.page-main.-start .value-animation .frame #page {
  margin: 0;
}
@media only screen and (max-width: 600px) {
  .page-main {
    /* For mobile phones: */
  }
  .page-main .spaceship {
    display: none;
  }
}
@font-face {
  font-family: "ln-website";
  src: url("fonts/ln-website.eot");
  src: url("fonts/ln-website.eot?#iefix") format("embedded-opentype"), url("fonts/ln-website.woff") format("woff"), url("fonts/ln-website.ttf") format("truetype"), url("fonts/ln-website.svg#ln-website") format("svg");
  font-weight: normal;
  font-style: normal;
}
[data-icon]:before {
  font-family: "ln-website" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "ln-website" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-arrow-back-1:before {
  content: "\6e";
}
.icon-arrow:before {
  content: "\6f";
}
.icon-arrow-down:before {
  content: "\70";
}
.icon-arrow-long:before {
  content: "\71";
}
.icon-arrow-long-down:before {
  content: "\72";
}
.icon-arrow-up:before {
  content: "\74";
}
.icon-close-1:before {
  content: "\75";
}
.icon-facebook:before {
  content: "\78";
}
.icon-logo-ln:before {
  content: "\79";
}
.icon-menu-1:before {
  content: "\7a";
}
.icon-twitter:before {
  content: "\41";
}
.icon-coffee-button:before {
  content: "\61";
}
.icon-phone:before {
  content: "\62";
}
.icon-location:before {
  content: "\63";
}
.icon-mail:before {
  content: "\64";
}
