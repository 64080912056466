/*
---
name: Close button
category: Buttons/Coffee
tag:
 - components
compile: true
---

```jade
button.button-close 
```
*/
.button-close {
    position:absolute;
    top:rem(30px);
    right:rem(50px);
    border: 0;
    border-radius:100%;
    width:rem(21px);
    height:rem(21px);
    cursor: pointer;
    margin: 0;
    &:before {
        font-size:rem(21px);
    }
      @media only screen and (max-width: @medium-break-point) {
          right: rem( 20px );
      }
}