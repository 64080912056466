.abstract-footer {
    background-color: @ln-white;
    padding: rem(40px) 0;
    margin-top:rem(-100px);

    .contact {
        margin-top: rem(24px);
    }
    
    .client {
        p {
            margin-bottom: rem(19px);
        }
        h4 {
            margin-bottom: rem(4px);
        }
        &.-left {
            clear:left;
            padding-top: rem(24px);
        }
    }
    .photo {
        float:left;
        border-radius: 100%;
        width: 129px;
        height: 129px;
        margin-right: rem(31px);
    }
}

@media screen  and (max-width: 600px) {
    .abstract-footer {
        text-align: center;
        margin-top: 0;
        .photo {
            float: none;
            margin-bottom: rem(15px);
        }
        .contact {
            margin-bottom: rem(15px);
        }
        
    }
}