/*
---
name: Coffee button
category: Buttons/Coffee
tag:
 - components
compile: true
---

```jade
button.button-coffee Gimme Coffee
```
*/

.button-coffee {
    height: 55px;
    width: 55px;
    color: white;
    background-color: @ln-red;
    box-shadow: 2px 2px 5px 0 rgba(0,0,0,0.30);
    border-radius: 100%;
    display:inline-block;
    position:relative;
    transition: all .3s ease-in-out;
    &:hover {  
        transform:scale(1.1,1.1);
    }

}
.-contact .button-coffee {
    visibility:hidden;
}
