/*
---
name: .list-service
category: Lists/Team
tag:
 - components
compile: true
---

```jade
div.list-service
	img.pic.responsive(src="http://placehold.it/350x150")
	h4.name Mein Name
	div.function Meine Funktion bei Lernetz
```
*/
.list-service {
   margin-bottom:rem(70px);
		> .name {
			font-size: rem( 25px );
			font-family: "Akkurat-Bold";
			margin:rem(25px) 0 rem(17px);
		}

		> .description {
			font-size: rem( 17px );
			font-family:"Akkurat-Light";
			padding-right:rem( 100px );
			letter-spacing: rem(0.19px);
			line-height: rem (26px);
		}
		>.formats-info-box{
			height: 100%;
			margin-bottom: 0;
			position: relative;
			transition: background-color .5s ease;
			position:relative;
			width:rem(290px);
			height:rem(290px);
		}
	}
	@media only screen and (max-width: @medium-break-point) {
        .list-service {
		text-align:center;
		> .description {
			padding-right:rem( 0px );
		}
      }
	}

	@media screen and (orientation:landscape) and (max-device-width: 780px) {
		 .grid  {
		  .list-service.medium-1-2 {
    			width: 100%;
				}
		 
		  }
	}
	
	.icon-strategie:hover .nadel {
		animation: rotate360 2s linear infinite;
		transform-origin: 50%;
	  }
	  
	  @keyframes rotate360 {
	  0%{
		  transform: rotate(0deg);
		}
	  100% {
		  transform: rotate(360deg);
		}
	  }
	  
        
