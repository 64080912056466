/*
---
name: .list-trainer
category: Lists/Trainer
tag:
 - components
compile: true
---

```jade
div.list-trainer
	img.pic.responsive(src="http://placehold.it/350x150")
	h4.name Mein Name
	div.function Meine Funktion bei Lernetz
```
*/
.-team {
	h2.trainer-title {
		margin-top: rem(175px);
		}
	p.lead {
		margin: 0 0 rem(70px) 0;
		padding:0;
	}
	.list-trainer {
		display: grid;
		margin: rem(22px) 0;
		grid-template-columns: rem(150px) auto;
		> .trainer-pic { 	
			> .pic {
					width:rem(130px);
					border-radius:100%;
				}
		}
			> .trainer-text {
				vertical-align: bottom;
				span {
					display: block;
    				margin-top: 50px;
				}

				> span, > p {
					font-weight: normal;
					font-size: rem(17px);
					font-family: "Akkurat-Light";
				}
			}
	}
	@media only screen and (max-width: @large-break-point) {
		.list-trainer {
			display: block;
			> .trainer-pic { 	
				text-align: center;
				> .pic {
					//width:50%;	
					}
			}
			> .trainer-text {
				text-align: center;
				span {
					margin-top:rem(20px);
					font-family: "Akkurat-Regular";
				}
			}
	
		}
	
	}
@media only screen and (max-width: @medium-break-point) {
	.list-trainer {
		display: block;
		> .trainer-pic { 	
			text-align: center;
			> .pic {
				width:50%;	
				}
		}
		> .trainer-text {
			text-align: center;
			span {
				font-family: "Akkurat-Regular";
			}
		}

	}

}
}