
.form-group {
    //display:grid;
    //grid-template-columns: 25% 75%;
    margin: 0 0 rem(15px) 0;
    font-size: rem(16px);
    label {
        display:block;
        font-size: rem( 13px );
        margin-bottom:rem( 3px );
    }
    .help-block {
        color: red;
        font-size: rem(16px);
        padding: rem(10px);
    }

    .input {
        line-height:rem( 40px );
        padding: 0 rem(5px);
        width: 50%;
        border: 2px solid black;
    }
    input[type=submit] {
        background:transparent; 
        cursor:pointer;
        padding: 5px 20px 3px 20px;
        border: 2px solid #000000;
        border-radius: 100px;
        font-size:rem( 14px );
        text-transform: uppercase;
    }
   textarea {
        line-height: 20px;
        padding: 0 rem(5px);
        width: 50%;
        height:8em;
        border: 2px solid black;
    }
    
    @media screen  and (max-width: @medium-break-point) {
        .input {
            width: 100%;
        }
   
    }

    /*
        &.-full {
            width:100%;
        }
        &.-half {
            width:49%;
        }
        */
}
