/*
---
name: .page-member
category: Page Layouts/Teaser
tag:
 - components
compile: true
---

```jade
.page-member
    div.member-image Some content
    div.member-detail Some content
```
*/

.-member {
    > .item-navigation {
        width: 100%;
        text-align: right;
        margin-bottom:15px;
    }
    > .member-container {
        > .member-details {
            h2 {
                font-family:"Akkurat-Regular";
                font-weight: 100;
                font-size: rem( 30px );
            }
            p {
                font-size:rem ( 17px );
                font-family:"Akkurat-Light";
                line-height:rem(26px);
                letter-spacing: (0.19px);
            }
            .social-links {
                margin-bottom: rem(26px);

                > a {
                    color:@ln-red;
                    margin-right: rem(16px);

                    &:hover .link-icon {
                        fill: darken(@ln-red,20%) !important;
                    }
                }
            }
        > .member-mail, > .member-phone {
                margin: 10px 0 25px 0;
                font-size: rem( 21px );
                color:@ln-red;
                > a {
                    color:@ln-red;
                }
            }
        }
    }
    
}
@media only screen and (max-width: @medium-break-point) {
    /* For mobile phones: */
   .-member {
    
    > .member-container {
        > .member-details {
            padding-bottom:100px;
            h2 {
                font-family:"Akkurat-Regular";
                font-size: rem( 30px ); 
            }
            p {
                font-size:rem ( 22px );
                font-family:"Akkurat-Light";
                line-height:rem(26px);
                letter-spacing: (0.15px);
            }
        > .member-mail, .member-phone {       
                font-size: rem( 14px );
            }
        }
        .link-icon {
            width:32px;

            &.-vcard {
                width: 22px !important;
            }

            &.-birdie {
                width: 22px;
                margin:0 !important;
            }
        }
    }
    
}
}

@media screen and (orientation:landscape) and (max-device-width: 780px) {
      .-member {
    
    > .member-container {
        > .member-details {
            h2 {
                font-family:"Akkurat-Regular";
                font-size: rem( 20px ); 
            }
            p {
                font-size:rem ( 22px );
                font-family:"Akkurat-Light";
                line-height:rem(26px);
                letter-spacing: (0.15px);
            }
        > .member-mail {       
                font-size: rem( 14px );
            }
        }
    }
    
}
    }