/*
---
name: .page-header
category: Layouts/Page Header
tag:
 - layouts
compile: true
---

```jade
.page-header.row
  .logo Logo
  .page Pagename
  .menu Menu
```

*/
.page-header {
    margin:0 auto -50px auto;
    padding: 12px 0;
    color: @ln-blue;
    background-color: @body-color;
    transition: background-color 0.5s;
    box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.30);

    .page {
      width: 100%;
      padding-left: rem(12px);
      font-size: rem( 15px );
    }

    .menu {
      font-size: 27px;
      transition: font-size 0.5s;
      cursor: pointer;
      display: inline-block;
      height: 27px;
      vertical-align: center;
    }
    
    .logo {
      transition: height 0.5s;
      height: 21px;
      width: auto;
    }
    .icon-logo-ln:before {
      font-size:rem(150px);
      line-height:0;
     
    }

    &.-colored {

      box-shadow: 0 0 0 0 rgba(0,0,0,0.30);

      .logo {
        height: 30px;
      }
      .menu {
        font-size: 34px;
      }
    }
    @media only screen and (max-width: @medium-break-point) {
    /* For mobile phones: */
      .page {
        visibility:hidden;
      }
    }
}