.paragraph-element {
    
    > a {
        color: black;
        border-bottom: 2px solid black;
        padding-bottom: 2px;
    }

    > a:hover {
        color: @ln-blue;
    }

    > a:visited {
        color:black;
    }

    &.lead > p {
        margin-bottom:rem( 70px );
    }

    ul, ol {
        margin-top: rem(20px);
        margin-left:rem(21px);
    }
}


@media screen  and (max-width: @medium-break-point) {

    .paragraph-element {
        &.lead > p {
            font-size:rem(28px);
            margin-bottom:rem( 50px );
        }
    }
}

@media screen  and (max-width: @small-break-point) {
    .paragraph-element {

        &.lead > p {
            font-size:rem(23px);
            margin-bottom:rem( 35px );
        }
    }
}