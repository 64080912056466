.link-element {
    margin-top: rem(10px);
    font-family: "Akkurat-light";
    font-size: rem(21px);
    
    .description {
        font-family: "Akkurat-Bold";
    }
    a:hover {
        color: @ln-blue;
    }

    &.-arrow {
        a:before {
            content: url(../images/arrow_right.svg);
            margin-right: rem(16px);
        }
    }

    &.-button {
        a {
            font-family: "Akkurat-Regular";
            font-weight: 400;
            background: transparent;
            cursor: pointer;
            padding: 5px 20px 3px 20px;
            border: 2px solid #000000;
            border-radius: 100px;
            font-size: 0.875rem;
            text-transform: uppercase;
        }
    }
   
}