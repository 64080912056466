a {
    color: #000000;
}
.paragraph-element a {
        text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
    font-family:"Akkurat-Bold";
}

h1 {
    font-size: rem(45px);
    letter-spacing: rem(0.49);
    line-height: rem(45);
    }

h2 {
    font-size: rem(36px);
    letter-spacing: rem(1.69);
    line-height: rem(46);
    text-transform: uppercase;
    margin-bottom: rem(20px);
}
h3 {
    font-size:rem(30);
    font-family: "Akkurat-Regular";
    line-height: rem(38);
}

h4 {
    font-size:rem(25px);
    line-height:rem(33px);
}


b, strong {
    font-family:"Akkurat-Bold";
}

p, ul, ol {
    font-size:rem(21px);
    font-family: "Akkurat-Light";
    line-height: rem(34px);
}

p.lead, .lead > p {
    font-size:rem(30px);
    margin-bottom:rem( 70px );
    line-height:rem( 38px );
    font-family:"Akkurat-Regular";
}

.small > p {
    font-size:rem(17px);
    font-family:"Akkurat-Regular";
    line-height:rem(26px);
    letter-spacing: (0.19px);
}




// @media only screen and (max-width: @medium-break-point) {
//     h2{
//         font-size: rem( 26px );
//         line-height:rem(36px);
//     }
//     p.lead {
//         font-size:rem(23px);
//         line-height:rem( 31px );
//         }
// }


@media screen  and (max-width: @medium-break-point) {

    h1 {
        font-size: rem(41px);
        letter-spacing: rem(0.45);
        line-height: rem(54);
    }
    
    h2 {
        font-size: rem(32px);
        letter-spacing: rem(1.5);
        line-height: rem(44);
    }
    
    h3 {
        font-size:rem(28);
        line-height: rem(38);
    }
    
    h4 {
        font-size:rem(23px);
        line-height:rem(33px);
    }

    p, ul, ol {
        font-size:rem(20px);
        line-height: rem(31px);
    }

}




@media screen  and (max-width: @small-break-point) {

    h1 {
        font-size: rem(30px);
        letter-spacing: rem(0.31);
        line-height: rem(40);
    }
    
    h2 {
        font-size: rem(26px);
        letter-spacing: rem(1.22);
        line-height: rem(36);
    }
    
    h3 {
        font-size:rem(23);
        line-height: rem(31);
    }
    
    h4 {
        font-size:rem(20px);
        line-height:rem(28px);
    }
    
    p, ul, ol {
        font-size:rem(17px);
        line-height: rem(27px);
    }
}