/*
---
name: .pic-overlay
category: Components/Overlay
tag:
 - components
compile: true
---

```jade
div.pic-overlay
    Some content
    div.overlay Overlay
```
*/

.pic-overlay {

    position: relative;
    overflow: hidden;

        img {
            display:block;
        }

    > .overlay {
        background-color: rgba( 0, 0, 0, 0.5 );
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translateY( 100% );
        transition: transform 0.3s;
        -webkit-font-smoothing: antialiased;
	    -moz-osx-font-smoothing: grayscale;
    }

	&:hover {
		> .overlay {
            transform: translateY( 0 );
		}
	}
}
