.titel-element {
    
    margin-bottom: rem(10px);

    h1 {
        margin-bottom:rem(45px);
    }
     
    h2 {
        margin-top:   rem(45px);
        margin-bottom: rem(20px);
    }
    
    h3 {
        margin-top: rem(45px);
        margin-bottom: rem(10px);
    }
    
    h4 {
        margin-top: rem(16px);
        margin-bottom: rem(10px);
    }

    & .-first {
            margin-top: rem(70px);
        }

    & .-last {
        padding-top: rem(50px);
        margin-top: rem(100px);
        border-top: 1px solid @ln-grey-medium;
        text-align: center;
    }
}

@media screen  and (max-width: @medium-break-point) {
    .titel-element {
        margin-bottom: 0;

        h1 {
            margin-bottom:rem(30px);
        }
        
        h2 {
            margin-top:   rem(40px);
            margin-bottom:rem(14px);
        }
        
        h3 {
            line-height: rem(38);
        }
        
        h4 {
            margin-top: rem(10px);
            margin-bottom: rem(10px);
        }
        

        & .-first {
            margin-top: rem(45px);
        }

        & .-last {
            padding-top: rem(30px);
            margin-top: rem(70px);
        }

    }

}




@media screen  and (max-width: @small-break-point) {
        
.titel-element {

        h1 {
            line-height: rem(40);
            margin-bottom:rem(20px);
        }
        
        h2 {
            margin-top:   rem(30px); 
            margin-bottom:rem(10px);
        }
        
        h3 {
            line-height: rem(31px);
        }
        
        h4 {
            margin-top: rem(20px);
            margin-bottom: rem(6px);
        }

        &.-last {
            padding-top: rem(20px);
            margin-top: rem(50px);
        }

    }
}