.colors( @back; @text; @hover ) {
    background-color: @back;
    color: @text;
    a {
        color: @text;
        &:hover {
            color: @hover;
        }
    }
    .logo {
        path {
            fill:@text;
        }
    }

}

/* first color: second color: third color: */
.home-colors-mixin {
    .colors( #011a33; #f3f3f2; @ln-blue );
}

.project-colors-mixin {
    .colors( #f3f3f2;  @ln-blue; @ln-blue);
}
.projects-colors-mixin {
    .colors( #f3f3f2;  @ln-blue; @ln-blue);
}

.events-colors-mixin {
    .colors( @ln-turkoise;  #f3f3f2;  #f3f3f2);
}

.team-colors-mixin {
    .colors( white; @ln-red; darken(@ln-red,15%));
}
.newsletter-colors-mixin {
    .colors( @ln-blue-offer; white; darken(white,15%));
}

.offer-colors-mixin {
    .colors( @ln-blue-offer; #f3f3f2; #f3f3f2);
}

.contact-colors-mixin {
    .colors( @ln-red;#d9d9da;#f3f3f2);
    }

.blog-colors-mixin {
      .colors( @ln-grey; #f3f3f2;  #f3f3f2);
    }

.center-vertical-mixin {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.center {
			display:block;
			margin:0 auto;
		}