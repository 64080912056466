/*
---
name: .page-contact
category: Layouts/Page Contact
tag:
 - layouts
compile: true
---

```jade
.page-contact.row
  .logo Logo
  .page Pagename
  .menu Menu
```

*/
.contact-header {
  font-family:"Akkurat-Bold";
  font-size:rem(30px);
  text-transform:uppercase;
  line-height: rem(38px);
  margin-bottom:35px;
}
.list-maps {
  position:relative;
  width:100%;
  height:100%;
  margin-bottom:35px;
  img {
    width:100%;
    height:auto;
  }
  &:hover .overlay {
      visibility: visible;
  }
  .overlay {
    z-index: 5;
    background:rgba(0,0,0,0.5);
    color:white;
    font-size:rem(25px);
    .location-pin {
      margin: 25px 0 0 25px;
    }
    .location {
      padding: 25px 0 0 50px;
    }
    visibility:hidden;
  }
   iframe {
       width:100%;
       height:100%;
   }
}
.-contact {
  & > .page-teaser {
    & > .content {
      & > .small {
        padding-top:rem( 50px );
        font-size:rem(30px);
        span {
          padding-right: rem( 30px );
          vertical-align: middle;
          a {
            font-family: "Akkurat-Regular";
          }
        }
        .icon-phone:before, .icon-mail:before {
            position: relative;
            top: rem(4px);
        }
      }
    }
  }
}

@media only screen and (max-width: @large-break-point) {
  /* For mobile phones: */
.list-maps {
  .overlay {
    visibility: visible;
  }
}
}

@media only screen and (max-width: @medium-break-point) {
    /* For mobile phones: */
    .-contact {
      & > .page-teaser {
        & > .content {
          & > .small {
            font-size:rem(23px);
            
            width:100%;
            padding-right: 0px;
            .icon-phone {
              display:block;
              margin-bottom:15px;
            }
          }
        }
      }
  }
  .list-maps {
    .overlay {
      font-size:rem( 20px );
      visibility: visible;
    }
  }
}