.image-container {

    margin-top: rem(15px);
    margin-bottom: rem(15px);
        
    .image {
        margin-top: rem(30px);
    }

    .caption {
        padding: 0;
        padding-left: rem(15px);
        font-size: rem(15px);
        font-family: "Akkurat-Light";
        line-height: rem(23px);
        letter-spacing: rem(0.16);
        margin-top: rem(11px);
    }
}

@media screen  and (max-width: @large-break-point) {
    .image-container {
        margin-top: rem(20px);
        margin-bottom: rem(20px);

        .caption {
            font-size: rem(13px);
            line-height: rem(21px);
            letter-spacing: rem(0.14);
            margin-top: rem(7px);
        }
    }
}



@media screen  and (max-width: @small-break-point) {
    .image-container {
        margin-top: rem(10px);
        margin-bottom: rem(10px);
        
        .image {
            margin-top: rem(20px);
            padding: 0;
        }
        .caption {
            font-size: rem(11px);
            letter-spacing: rem(0.12px);
            line-height: rem(17px);
            padding: 0;
        }
    }
}