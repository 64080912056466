/*
---
name: .page-teaser
category: Page Layouts/Teaser
tag:
 - components
compile: true
---

```jade
.page-teaser
    div.content Some content
    div.arrow Arrow
    div.socialmedia Socialmedia
```
*/

.page-teaser {
    position:relative;
    height: 100vh;
    font-size: rem(45px);

    h1 {
        line-height: normal;
        font-weight: normal;
        font-family: "Akkurat-Regular";
    }
    
    .mobile {
        display:none;
    }
    
    > .content {
        .center-vertical-mixin;
        a {
            font-family: "Akkurat-Bold";
            &:hover {
                font-family: "Akkurat-Bold";
            }
        }
    }

    > .arrow {
       font-size: rem(25px);
		display:block;
        position:absolute;
        margin: 0 50%;
        bottom: rem(60px);
        text-align: center;
        z-index:5;
        animation-name: arrow-up-down;
    	animation-duration: 1s;
   		animation-iteration-count: 3;
		animation-timing-function: ease-in-out;
        &:before {
            font-size:rem(46px);
        }
    }

    > .socialmedia {
        position: absolute;
        bottom: rem(20px);
        left: 0;
        width: 100%;
        z-index:5;
    }
}

@media only screen 
and (max-width : @large-break-point) 
and (orientation : landscape) { 
    .page-teaser {
        font-size: rem( 55px );
        .mobile {
            display:none;
            }
        .desktop {
            display:block;
            }
    }
}

@media only screen and (max-width: @medium-break-point) {
    /* For mobile phones: */
    .page-teaser {
        .mobile {
            display:inherit;
            }
        .desktop {
            display:none;
            }
        font-size: rem( 30px );
        .arrow {
            font-size: rem( 20px );
            bottom: rem( 30px );
            animation-name: arrow-up-down-small;
        }
    }
}
