/*
---
name: Lernetz Logo
category: Components/Logo
tag:
 - components
---

```html
<svg width="166px" height="30px" viewBox="0 0 166 30" class="image-lnlogo">
    <g>
        <path d="M23.5049332,15.806 L23.5049332,25.6907778 L37.3546374,25.6907778 C38.3002732,25.6907778 39.0740105,26.4351111 39.0740105,27.3451111 C39.0740105,28.2547222 38.3002732,28.9990556 37.3546374,28.9990556 L21.6257454,28.9990556 C20.6801097,28.9990556 19.9059825,28.2271111 19.9059825,27.2836667 L19.9059825,2.10972222 C19.9059825,1.16627778 20.6801097,0.394333333 21.6257454,0.394333333 L37.3546374,0.394333333 C38.3002732,0.394333333 39.0740105,1.13827778 39.0740105,2.04827778 C39.0740105,2.95788889 38.3002732,3.70222222 37.3546374,3.70222222 L23.5049332,3.70222222 L23.5049332,12.4168333 L35.454011,12.4168333 C36.3996467,12.4168333 37.1733841,13.1794444 37.1733841,14.1112222 C37.1733841,15.0433889 36.3996467,15.806 35.454011,15.806 L23.5049332,15.806 Z" id="Fill-1" fill="#F3F3F2"></path>
        <path d="M53.3956751,13.7482722 L47.472004,13.7482722 L47.472004,3.70210556 L53.3956751,3.70210556 C54.3413108,3.70210556 56.450476,3.73477222 57.3696058,3.95760556 C57.3696058,3.95760556 60.9938931,4.52849444 60.9385426,8.74560556 C60.8251131,12.28255 59.013749,13.1023278 57.1922504,13.5409944 C55.518483,13.8326611 54.3413108,13.7482722 53.3956751,13.7482722 L53.3956751,13.7482722 Z M62.4205323,23.7003278 C62.0662112,22.9241056 61.6744701,22.0732167 61.2862372,21.2343833 L59.0628628,16.4016611 C60.1698725,15.9742722 60.6840083,15.8086056 61.4663211,15.2439389 C62.2552604,14.6749944 62.8368303,14.1737167 63.5162379,13.0649944 C63.5162379,13.0649944 64.779944,11.2422722 64.6587187,8.74560556 C64.6669043,5.94677222 63.4753098,4.33521667 63.4753098,4.33521667 C62.9841717,3.50921667 62.0256728,2.40593889 61.1599445,1.92060556 C59.5298338,0.787772222 57.0686863,0.510883333 57.0686863,0.510883333 C56.1351342,0.359216667 54.3413108,0.394216667 53.3956751,0.394216667 L45.5928162,0.394216667 C44.6471805,0.394216667 43.8730533,1.16616111 43.8730533,2.10960556 L43.8730533,27.28355 C43.8730533,28.2273833 44.6471805,28.9993278 45.5928162,28.9993278 L45.752631,28.9993278 C46.6982667,28.9993278 47.472004,28.2273833 47.472004,27.28355 L47.472004,17.0666611 L55.398427,17.0969944 L55.4151881,17.1304389 C55.4385756,17.2128833 55.4619631,17.2957167 55.4989934,17.3758278 L60.3745134,27.9722722 C60.768983,28.8301611 61.7949159,29.2097167 62.6544075,28.8157722 L62.8426771,28.7298278 C63.7021688,28.3362722 64.0826059,27.3123278 63.6881363,26.4548278 L62.4205323,23.7003278 Z" id="Fill-3" fill="#F3F3F2"></path>
        <path d="M133.21033,3.74258889 L133.21033,27.2835889 C133.21033,28.2270333 132.436593,28.9993667 131.490957,28.9993667 L131.290214,28.9993667 C130.344579,28.9993667 129.570841,28.2270333 129.570841,27.2835889 L129.570841,3.74258889 L122.757665,3.74258889 C121.811639,3.74258889 121.037902,2.98931111 121.037902,2.06842222 C121.037902,1.14753333 121.811639,0.394255556 122.757665,0.394255556 L140.023117,0.394255556 C140.969143,0.394255556 141.74288,1.14753333 141.74288,2.06842222 C141.74288,2.98931111 140.969143,3.74258889 140.023117,3.74258889 L133.21033,3.74258889 Z" id="Fill-5" fill="#F3F3F2"></path>
        <path d="M3.59879483,1.87584444 L3.59879483,25.4164556 L15.669098,25.4164556 C16.6151235,25.4164556 17.3888608,26.1697333 17.3888608,27.0910111 C17.3888608,28.0119 16.6151235,28.7655667 15.669098,28.7655667 L1.71960696,28.7655667 C0.773971255,28.7655667 -0.000155916852,27.9932333 -0.000155916852,27.0497889 L-0.000155916852,1.87584444 C-0.000155916852,0.9324 0.773971255,0.160455556 1.71960696,0.160455556 L1.87942174,0.160455556 C2.82505745,0.160455556 3.59879483,0.9324 3.59879483,1.87584444" id="Fill-7" fill="#F3F3F2" mask="url(#mask-2)"></path>
        <path d="M102.879786,15.8184056 L102.879786,25.7031833 L116.72949,25.7031833 C117.675126,25.7031833 118.448863,26.4475167 118.448863,27.3575167 C118.448863,28.2671278 117.675126,29.0114611 116.72949,29.0114611 L101.000598,29.0114611 C100.054963,29.0114611 99.2808353,28.2395167 99.2808353,27.2960722 L99.2808353,2.12212778 C99.2808353,1.17868333 100.054963,0.40635 101.000598,0.40635 L116.72949,0.40635 C117.675126,0.40635 118.448863,1.15068333 118.448863,2.06068333 C118.448863,2.97029444 117.675126,3.71462778 116.72949,3.71462778 L102.879786,3.71462778 L102.879786,12.4292389 L114.828864,12.4292389 C115.7745,12.4292389 116.548237,13.19185 116.548237,14.1236278 C116.548237,15.0557944 115.7745,15.8184056 114.828864,15.8184056 L102.879786,15.8184056 Z" id="Fill-10" fill="#F3F3F2"></path>
        <path d="M165.446334,3.12526667 C165.628757,2.86782222 165.738289,2.55593333 165.738289,2.21876667 L165.738289,1.99787778 C165.738289,1.1291 165.026139,0.4186 164.155343,0.4186 L145.897869,0.4186 C145.027074,0.4186 144.314534,1.1291 144.314534,1.99787778 L144.314534,2.21876667 C144.314534,3.08754444 145.027074,3.79804444 145.897869,3.79804444 L160.496364,3.79804444 L144.768642,26.1506 C144.748762,26.1782111 144.737068,26.2085444 144.719138,26.2365444 C144.510209,26.5044889 144.380019,26.8362111 144.380019,27.2002111 L144.380019,27.4207111 C144.380019,28.2894889 145.092559,29.0003778 145.963354,29.0003778 L164.099603,29.0003778 C164.970398,29.0003778 165.682549,28.2894889 165.682549,27.4207111 L165.682549,27.2002111 C165.682549,26.3314333 164.970398,25.6205444 164.099603,25.6205444 L149.636755,25.6205444 L165.364478,3.26798889 C165.397221,3.22248889 165.418659,3.17271111 165.446334,3.12526667" id="Fill-12" fill="#F3F3F2"></path>
        <path d="M2.95711902,14.7009333 C4.45470039,16.5337667 8.93419156,21.9817111 8.93419156,21.9817111 L8.94159761,1.87071111 C8.94159761,0.926877778 9.71572478,0.154933333 10.6613605,0.154933333 L10.855477,0.159988889 C11.8011127,0.159988889 12.5748501,0.931933333 12.5748501,1.87537778 L12.5748501,27.0489333 C12.5748501,27.9923778 11.8011127,28.7647111 10.855477,28.7647111 L10.8110407,28.7647111 C10.3686266,28.7604333 10.0295074,28.7059889 9.66076409,28.4434889 C9.40817879,28.2334889 9.41870318,28.2439889 9.26044757,28.0651 C7.09788083,25.4486556 1.00192169,18.0010444 0.143989213,16.9623222 C0.057065568,16.8425444 0.192323437,16.7212111 0.192323437,16.7212111 C0.192323437,16.7212111 2.57707169,14.7728778 2.70297455,14.6671 C2.83550388,14.5628778 2.95711902,14.7009333 2.95711902,14.7009333" id="Fill-14" fill="#F3F3F2" mask="url(#mask-4)" transform="translate(81.466555, 0.233839)"></path><path d="M79.3635489,14.4023056 L73.3658173,7.17169444 L73.3580215,27.2830833 C73.3580215,28.2265278 72.5842841,28.9984722 71.6386484,28.9984722 L71.4445319,28.9938056 C70.4988962,28.9938056 69.7251588,28.2218611 69.7251588,27.2780278 L69.7251588,2.10447222 C69.7251588,1.16102778 70.4988962,0.389083333 71.4445319,0.389083333 L71.4889682,0.389083333 C71.9313823,0.393361111 72.2705015,0.447416667 72.6392448,0.709916667 C72.8918301,0.920305556 72.8813057,0.909805556 73.0391715,1.08830556 C75.2177198,3.72419444 81.3557764,11.1873611 82.1419872,12.1381944 C82.2659411,12.2758611 82.1529013,12.37775 82.1529013,12.37775 C82.1529013,12.37775 80.3668738,13.8395833 79.6188627,14.4423611 C79.4836048,14.5489167 79.376412,14.4104722 79.376412,14.4104722" id="Fill-17" fill="#F3F3F2"></path>
    </g>
</svg>
```
*/

.image-lnlogo {

    &.-offer {
        fill: #f3f3f2;
    }

     &.-team {
        fill: @ln-red;
    }
    &.-blog {
        fill: #f3f3f2;
    }
    &.-contact {
        fill: #e3e3e5;
    }
    &.-project {
        fill: @ln-blue;
    }
}