.-project {

    .item-navigation a {
        color: @ln-blue;
    }

    a {
        color: @ln-blue;
        display:inline-block;
        border-bottom:1px solid @ln-blue;
        line-height:normal;
     }

     .navigation-main .external-links > ul > li a {
        line-height: 3.125rem;
     }
      a.next, a.arrow, .item-navigation a, .header a, .navigation a {
         border:none;
     }
     .navigation a {
         display:inline;
     }

     .navigation a.hidden {
        display:none;
    }


    .teaser {
        font-size: rem(45px);
        font-family: "Akkurat-Regular";
        line-height: rem(58px);
        letter-spacing: rem(0.4);
        margin: rem(98px) 0 rem(35px) 0;
        font-weight: normal;
    }

    .content-element {

        p, h1, h2, h3, h4, ul, ol {
            padding-left: rem(78px);

            &.last {
                padding-left: 0;
            }
        }

        .quote-element p {
            padding-left: 0;
        }
    }



    .item-navigation.-outro{
            text-align: left;
            line-height: rem(26px);
            font-size: rem(15px);
            
        > a.next {
            position:relative;
            top: -2px;
            left: 2px;
        }
    }

}

@media screen  and (max-width: @medium-break-point) {
    .-project {
        .content-element {
            p, h1, h2, h3, h4, ul, ol, .quote-element {
                padding-left: 0;
            }
        }

        .teaser {
            font-size: rem(41px);
            line-height: rem(54px);
            letter-spacing: rem(0.45);
            margin-top: rem(70px);
        }
       
    }
}

@media screen  and (max-width: @small-break-point) {
    .-project {
        .teaser {
            font-size: rem(30px);
            line-height: rem(40px);
            letter-spacing: rem(0.33);
            margin-top: rem(35px);
            margin-bottom: rem(20px);
        }
    }
}