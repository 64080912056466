.navigation-main {
    font-size: rem(14px);
    padding: 80px 50px;
    >ul {
        list-style: none;
        >li {
            font-size: rem(25px);
            margin-bottom: rem(15px);
            >div {
                >a {
                    text-transform: uppercase;
                    font-family: "Akkurat-Bold";
                    &:hover {
                        border-bottom: 3px solid;
                    }
                }
                >.icon-external {
                    .icon {
                        display:inline-block;
                        height: 12px;
                        width: 12px;
                        background-repeat: no-repeat;
                        background-color: currentColor;
                        mask-image: url(../images/link-external.svg);
                        margin:0 0 3px 10px;
                    }
                }
            }
        }
    }
    .navigation-coffee {
        margin: 50px 0;
        .coffee-text {
            width: 70%;
            float: left;
            >h3 {
                font-family: "Akkurat-Bold";
                font-size: rem(17px);
                line-height: rem(25px);
            }
            >p {
                float: left;
            }
        }
    }
    .navigation-adresse {
        clear: both;
        margin-top: 150px;
        >strong {
            display: block;
            margin-bottom: 10px;
        }
        .left {
            width: 45%;
            float: left;
            &.divider {
                border-right: 2px solid;
                padding-right: 10px;
            }
        }
        .right {
            padding: 0 0 10px 10px;
            float: left;
            width: auto;
        }
    }
    .button-newsletter {
        margin-top: rem( 20px);
        clear: both;
        button {
            position: relative;
            background: transparent;
            border-radius: rem( 25px);
            border: 2px solid;
            text-transform: uppercase;
            line-height: rem( 26px);
            padding: 4px 20px 2px 35px;
            cursor: pointer;
            .icon {
                position: absolute;
                left: 12px;
                top: 6px;
                height: 16px;
                width: 14px;
                background-color: currentColor;
                mask-image: url(../images/pen.svg);
            }
        }
    }
    .mail-phone {
        padding: 10px 0;
        strong {
            display: inherit;
        }
        clear:both;
    }
    .external-links {
        margin: 30px 0 0 0;
        clear: both;
        >ul {
            >li {
                display: block;
                position: relative;
                width: 100%;
                margin: 0;
                border-bottom: 2px solid;
                line-height: rem(50px);
                &:hover {
                    color: inherit;
                    &:before {
                        color: inherit;
                    }
                }
                &:before {
                    position: absolute;
                    right: 0;
                    top: 10px;
                    font-size: 30px;
                }
                &:first-child {
                    border-top: 2px solid;
                }
                >a {
                    font-family: "Akkurat-Bold";
                    text-transform: uppercase;
                    display: block;
                    &:hover {
                        border-bottom: none;
                        color: inherit;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: @medium-break-point) {
        /* For mobile phones: */
        font-size: rem(14px);
        padding: 80px 20px;
        &>ul {
            list-style: none;
            >li {
                font-size: rem(20px);
                margin-bottom: rem(15px);
                >a {
                    text-transform: uppercase;
                    font-family: "Akkurat-Bold";
                    &:hover {
                        border-bottom: 3px solid;
                    }
                }
            }
        }
        .navigation-adresse {
            margin-top: 50px;
            font-size: rem(11px);
            .left {
                width: 100%;
                padding: 0 0 5px 0;
                &.divider {
                    border-right: none;
                }
            }
            .right {
                padding: 5px 0 10px 0px;
                width: 100%;
            }
            .mail-phone {
                padding: 30px 0;
                .left,
                .right {
                    padding: 0;
                }
            }
        }
        .external-links {
            >ul {
                >li {
                    font-size: rem (17px);
                }
            }
        }
    }
}